var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 py-5 align-items-center"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Your report updates")) + " ")])]), _c('div', {
    staticClass: "card-body pt-0"
  }, [_vm._l(_vm.reports, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "bg-light-secondary rounded p-5 mb-5 cursor-default"
    }, [_c('div', {
      staticClass: "d-flex align-items-center updates-chip"
    }, [_c('div', {
      staticClass: "mr-4"
    }, [_c('CountryFlag', {
      attrs: {
        "country-iso": item.action_object_object_id
      }
    })], 1), _c('div', {
      staticClass: "font-weight-bolder text-dark-75 font-size-xs text-capitalize"
    }, [item.target_representation != 'None' ? _c('span', [_vm._v(" " + _vm._s(item.target_representation) + " ")]) : _c('span', [_vm._v(" " + _vm._s(item.actor_representation) + " ")]), _vm._v(" " + _vm._s(_vm.$t("Update")) + " "), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "updates-chip-date",
      attrs: {
        "title": _vm.DateService.format(item.timestamp, _vm.dateFormat + ' HH:mm:ss')
      }
    }, [_vm._v(_vm._s(_vm.DateService.timeAgo(item.timestamp)))])])])]);
  }), _vm.reports.length == 0 ? _c('div', [_c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cannot find reports updates.")))]), _c('div', {
    staticClass: "mt-7"
  }, [_c('router-link', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase w-60",
    attrs: {
      "to": {
        name: 'reports'
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Cart1.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Subscribe here")) + " ")])], 1)]) : _vm._e()], 2)])]), _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 py-5 align-items-center"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Your map updates")) + " ")])]), _c('div', {
    staticClass: "card-body pt-0"
  }, [_vm._l(_vm.maps, function (item) {
    var _vm$countriesMap$item;
    return _c('div', {
      key: item.id,
      staticClass: "bg-light-secondary rounded p-5 mb-5 cursor-default"
    }, [_c('div', {
      staticClass: "d-flex align-items-center updates-chip"
    }, [_c('div', {
      staticClass: "mr-4"
    }, [_c('CountryFlag', {
      attrs: {
        "country-iso": item.target_object_id
      }
    })], 1), _c('div', {
      staticClass: "font-weight-bolder text-dark-75 font-size-xs text-capitalize"
    }, [_c('span', [_vm._v(" " + _vm._s((_vm$countriesMap$item = _vm.countriesMap[item.target_object_id]) === null || _vm$countriesMap$item === void 0 ? void 0 : _vm$countriesMap$item.name) + " " + _vm._s(item.verb.split(" ")[0]) + " ")]), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "updates-chip-date",
      attrs: {
        "title": _vm.DateService.format(item.timestamp, _vm.dateFormat + ' HH:mm:ss')
      }
    }, [_vm._v(_vm._s(_vm.DateService.timeAgo(item.timestamp)))])])])]);
  }), _vm.maps.length == 0 ? _c('div', [_c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cannot find map updates.")))]), _c('div', {
    staticClass: "mt-7"
  }, [_c('router-link', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase w-60",
    attrs: {
      "to": {
        name: 'reports'
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Cart1.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Subscribe here")) + " ")])], 1)]) : _vm._e()], 2)])]), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 py-5 align-items-center"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Your assignments updates")) + " ")])]), _c('div', {
    staticClass: "card-body pt-0"
  }, [_vm._l(_vm.secondments, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "bg-light-secondary rounded p-5 mb-5 cursor-default"
    }, [_c('div', {
      staticClass: "d-flex align-items-center updates-chip"
    }, [_c('div', {
      staticClass: "font-weight-bolder text-dark-75 font-size-xs text-capitalize mr-2"
    }, [_c('span', [_vm._v(" " + _vm._s(item.target_representation) + " ")])]), _c('div', {
      staticClass: "font-weight-normal font-size-xs text-capitalize"
    }, [_c('span', [_vm._v(" " + _vm._s(item.verb) + " ")])]), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "updates-chip-date",
      attrs: {
        "title": _vm.DateService.format(item.timestamp, _vm.dateFormat + ' HH:mm:ss')
      }
    }, [_vm._v(_vm._s(_vm.DateService.timeAgo(item.timestamp)))])])]);
  }), _vm.secondments.length == 0 ? _c('div', [_c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cannot find assignments updates. Make sure you have at least one assignment.")))])]) : _vm._e()], 2)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }