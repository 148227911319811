var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 py-5 align-items-center"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employee distribution over time")) + " ")]), _c('div', {
    staticClass: "card-toolbar m-0"
  }, [_c('EuroSelect', {
    staticClass: "gantt-select mb-n1",
    attrs: {
      "options": _vm.selectComputed
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)]), _c('div', {
    staticClass: "card-body pt-0"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_vm.ganttItems.length > 0 ? [_c('GanttChart', {
    attrs: {
      "data": _vm.ganttItems
    },
    on: {
      "columnAction": _vm.handleAction
    }
  })] : [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('h3', [_c('i', {
    staticClass: "flaticon2-graphic-1 text-primary svg-icon-lg",
    staticStyle: {
      "font-size": "2.4rem"
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("No data available at this time.")) + " ")])])]), _c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("No data found for this graph.")))])]], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }