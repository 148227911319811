import api from "@/core/services/api.service"

export default {
  get PATH() {
    return "riskmaps"
  },

  async getNaturalDisasters(country = null, disaster_type = null, limit = null) {
    const r = await api.get(`${this.PATH}/get_last_natural_disaster/`, {params: {country, disaster_type, limit}})
    return r.data
  }
}