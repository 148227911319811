<template>
  <div class="row">
    <div class="col-12">
      <secondments-map-widget />
    </div>
    <div class="col-12 col-lg-9">
      <next-secondments-widget :next-secondments="nextSecondments" :is-loading="isLoading" />
    </div>
    <div class="col-12 col-lg-3">
      <risk-level-widget />
    </div>
    <div class="col-12">
      <notification-updates-report></notification-updates-report>
    </div>
    <div class="col-12 col-lg-9">
      <gantt-widget :gantt-data="employeeGantt" :is-loading="isLoading" />
    </div>
    <div class="col-12 col-lg-3">
      <expairing-widget />
    </div>
    <div class="col-12 col-lg-6">
      <nations-secondments-days :filter-value.sync="nationSecondmentDaysFilter"
        :nations-secondments="filteredNationsSecondmentsByCountry" :is-loading="isLoading" />
    </div>
    <div class="col-12 col-lg-6">
      <secondments-days-for-declaration :filter-value.sync="nationSecondmentDaysFilter"
        :employees-days-for-declaration-secondments="employeesDaysForDeclaration" :is-loading="isLoading" />
    </div>
    <div class="col-12 col-lg-6">
      <secondments-per-nation :secondments-distribution="secondmentsDistribution" :is-loading="isLoading" />
    </div>
    <div class="col-12 col-lg-6">
      <best-clients-chart class="mb-3" :client-count="secondmentsCountPerClient" :is-loading="isLoading" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardService from "@/core/services/dashboard/dashboard.service";
import icons from "@/core/config/icons.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NextSecondmentsWidget from "@/view/content/widgets/dashboard/NextSecondmentsWidget.vue";
import SecondmentsMapWidget from "@/view/content/widgets/dashboard/SecondmentsMapWidget.vue";
import GanttWidget from "@/view/content/widgets/dashboard/GanttWidget.vue";
import NationsSecondmentsDays from "@/view/content/widgets/dashboard/NationsSecondmentsDays.vue";
import SecondmentsPerNation from "@/view/content/widgets/dashboard/SecondmentsPerNation.vue";
import BestClientsChart from "@/view/content/widgets/dashboard/BestClientsChart.vue";
import RiskLevelWidget from "@/view/content/widgets/dashboard/RiskLevelWidget.vue";
import NotificationUpdatesReport from "@/view/components/tables/notification/NotificationUpdatesReport.vue";
import SecondmentsDaysForDeclaration from "@/view/content/widgets/dashboard/SecondmentsDaysForDeclaration.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import ExpairingWidget from "@/view/content/widgets/dashboard/ExpairingWidget.vue";

export default {
  name: "Dashboard",
  components: {
    SecondmentsDaysForDeclaration,
    NextSecondmentsWidget,
    SecondmentsMapWidget,
    GanttWidget,
    NationsSecondmentsDays,
    SecondmentsPerNation,
    BestClientsChart,
    RiskLevelWidget,
    NotificationUpdatesReport,
    ExpairingWidget,
  },
  data() {
    return {
      icons,
      apiData: {},
      nextSecondments: [],
      secondmentsCountPerClient: [],
      employeeGantt: [],
      secondmentsDistribution: {},
      employeesDaysForDeclaration: {},
      isLoading: true,
      nationSecondmentDaysFilter: "current",
    };
  },

  computed: {
    ...mapGetters("constants", ["countriesMap"]),

    filteredNationsSecondmentsByCountry() {
      switch (this.nationSecondmentDaysFilter) {
        case "current":
          return this.makeSecondmentDaysPerCountry(this.apiData?.secondments_days_per_country_this_year ?? {});
        case "last12Months":
          return this.makeSecondmentDaysPerCountry(this.apiData?.secondments_days_per_country_last_year ?? {});
        case "next12Months":
          return this.makeSecondmentDaysPerCountry(this.apiData?.secondments_days_per_country_next_year ?? {});
        default:
          return [];
      }
    },
  },

  async created() {
    await DashboardService.getData().then(res => {
      this.apiData = res.data;

      this.secondmentsDistribution = res.data.secondments_count_per_country;

      this.employeeGantt = res.data.secondments_employees_time_distribution;

      this.employeesDaysForDeclaration = res.data.employees_days_per_country_in_fiscal_year;

      const clientsForCount = Object.keys(res.data.secondments_count_per_client);
      for (const client of clientsForCount) {
        this.secondmentsCountPerClient.push({
          client: res.data.secondments_count_per_client[client]["client_name"],
          secondments: res.data.secondments_count_per_client[client]["count"],
        });
      }

      this.nextSecondments = res.data?.next_secondments.map(el => {
        const newEl = {
          id: el.id,
          client: {
            id: el?.clients[0]?.client ?? null,
            name: el?.clients[0]?.name ?? "",
            initials: el?.clients[0]?.name[0] ?? "",
            logo: el?.clients[0]?.logo ?? null
          },
          nation: {
            name: this.countriesMap[el.country]?.name,
            iso: el.country,
          },
          dateStart: el.date_start,
          dateEnd: el.date_end,
          status: el.status,
        };

        return newEl;
      });
    }).catch(err => {
      backendErrorSwal(err, "Failed to load dashboard data.")
    }).finally(() => {
      this.isLoading = false;
    });
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Dashboard") }]);
  },

  methods: {
    makeSecondmentDaysPerCountry(secondmentsPerCountry) {
      const nationsWithSecondments = Object.keys(secondmentsPerCountry);
      return nationsWithSecondments.map(iso => {
        return {
          id: iso,
          highest: secondmentsPerCountry[iso]?.days,
          nation: {
            iso,
            name: this.countriesMap[iso]?.name,
          },
          secondments: secondmentsPerCountry[iso].secondment_data,
        };
      });
    },
  },
};
</script>

<style scoped>
::v-deep .custom-bg {
  background: linear-gradient(to top, #48abff, #3b5b93) !important;
}

::v-deep .dropdown-menu .active>.dropdown-item,
::v-deep .dropdown-menu .active:hover>.dropdown-item {
  background-color: #003774;
  color: white;
}
</style>
