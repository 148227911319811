<template>
  <div ref="ganttChart" class="gantt-chart"></div>
</template>

<style scoped>
.gantt-chart {
  height: 280px;
}
</style>

<script>
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import _ from "lodash";

import { mapGetters } from "vuex";

const am5DateFormats = {
  "DD/MM/YYYY": "dd/MM/yyyy",
  "DD-MM-YYYY": "dd-MM-yyyy",
  "YYYY/MM/DD": "yyyy/MM/dd",
  "YYYY-MM-DD": "yyyy-MM-dd",
};

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    xPreview: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      root: null,
      chart: null,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),

    chartDateFormat() {
      return am5DateFormats[this.dateFormat] || "yyyy-MM-dd";
    },
  },

  watch: {
    data() {
      this.makeChart();
    },
  },

  mounted() {
    let root = am5.Root.new(this.$refs.ganttChart);
    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX", "openValueX"],
    });

    // Set a theme.
    root.setThemes([am5themes_Animated.new(root)]);
    this.root = root;

    this.makeChart();
  },

  methods: {
    makeChart() {
      const root = this.root;

      if (this.chart) {
        this.chart.dispose();
      }

      // Create chart.
      this.chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          layout: root.verticalLayout,
        }),
      );
      const chart = this.chart;

      let overlay = root.container.children.push(
        am5.Container.new(root, {
          width: am5.p100,
          height: am5.p100,
          layer: 100,
          visible: false,
        }),
      );

      overlay.children.push(
        am5.Rectangle.new(root, {
          width: am5.p100,
          height: am5.p100,
          fill: am5.color(0x000000),
          fillOpacity: 0.3,
        }),
      );

      overlay.children.push(
        am5.Label.new(root, {
          text: "Use CTRL + Scroll to zoom",
          fontSize: 30,
          x: am5.p50,
          y: am5.p50,
          centerX: am5.p50,
          centerY: am5.p50,
        }),
      );

      chart.events.on("wheel", function (ev) {
        if (ev.originalEvent.ctrlKey) {
          ev.originalEvent.preventDefault();
          chart.set("wheelY", "zoomX");
        } else {
          chart.set("wheelY", "none");
          overlay.show();
          overlay.setTimeout(function () {
            overlay.hide();
          }, 800);
        }
      });

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      );

      // Data
      var data = this.data.map((x) => {
        return {
          category: x.label,
          fromDate: x.dateStart,
          toDate: x.dateEnd,
          redirect: x.redirect,
          columnSettings: {
            fill: am5.color(x.color ?? "#ffd250"),
          },
        };
      });

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          autoGridCount: false,
          gridCount: 1000,
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.2,
            cellEndLocation: 0.8,
            minGridDistance: 5,
          }),
          tooltip: am5.Tooltip.new(root, {
            themeTags: ["axis"],
            animationDuration: 200,
          }),
        }),
      );

      const uniqueCategories = _.uniqBy(data, "category").map((x) => ({ category: x.category }));
      yAxis.data.setAll(uniqueCategories);

      var xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: "minute", count: 1 },
          renderer: am5xy.AxisRendererX.new(root, {}),
        }),
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          openValueXField: "fromDate",
          valueXField: "toDate",
          categoryYField: "category",
          sequencedInterpolation: true,
        }),
      );

      series.columns.template.events.on("click", (ev) => {
        if (ev.target.dataItem.dataContext.redirect)
          this.$emit("columnAction", ev.target.dataItem.dataContext.redirect);
      });

      series.columns.template.setAll({
        height: am5.p100,
        templateField: "columnSettings",
        strokeOpacity: 0,
        tooltipText: `{openValueX.formatDate('${this.chartDateFormat}')} - {valueX.formatDate('${this.chartDateFormat}')}`,
      });

      series.data.processor = am5.DataProcessor.new(root, {
        dateFields: ["fromDate", "toDate"],
        dateFormat: "yyyy-MM-dd",
      });

      series.data.setAll(data);

      // Add scrollbars
      const scrollbarX = am5xy.XYChartScrollbar.new(root, {
        orientation: "horizontal",
        height: this.xPreview ? 50 : null,
      });

      chart.set("scrollbarX", scrollbarX);
      chart.bottomAxesContainer.children.push(scrollbarX);

      const scrollbarY = am5.Scrollbar.new(root, {
        orientation: "vertical",
      });
      chart.set("scrollbarY", scrollbarY);
      chart.leftAxesContainer.children.push(scrollbarY);

      if (this.xPreview) {
        let sbxAxis = scrollbarX.chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            baseInterval: { timeUnit: "minute", count: 1 },
            renderer: am5xy.AxisRendererX.new(root, {}),
          }),
        );

        let sbyAxis = scrollbarX.chart.yAxes.push(
          am5xy.CategoryAxis.new(root, {
            categoryField: "category",
            renderer: am5xy.AxisRendererY.new(root, {
              inversed: true,
              cellStartLocation: 0.2,
              cellEndLocation: 0.8,
            }),
            tooltip: am5.Tooltip.new(root, {
              themeTags: ["axis"],
              animationDuration: 200,
            }),
          }),
        );

        sbyAxis.data.setAll(uniqueCategories);

        let sbseries = scrollbarX.chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: sbxAxis,
            yAxis: sbyAxis,
            openValueXField: "fromDate",
            valueXField: "toDate",
            categoryYField: "category",
            sequencedInterpolation: true,
          }),
        );

        sbseries.columns.template.setAll({
          height: am5.p100,
          templateField: "columnSettings",
          strokeOpacity: 0,
          tooltipText: "{openValueX.formatDate('yyyy-MM-dd')} - {valueX.formatDate('yyyy-MM-dd')}",
        });

        sbseries.data.processor = am5.DataProcessor.new(root, {
          dateFields: ["fromDate", "toDate"],
          dateFormat: "yyyy-MM-dd",
        });

        sbseries.data.setAll(data);
      }

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
      chart.appear(1000, 100);
    },
  },
};
</script>
