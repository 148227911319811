<template>
  <span class="label label-lg label-inline font-weight-bolder text-capitalize" :class="variant">
    {{ status }}
    <span v-if="past" class="ml-1">({{ $t('Past') }})</span>
    <span v-if="started" class="ml-1">({{ $t('Started') }})</span>
  </span>
</template>

<script>
import { STATUS_TYPE } from "@/core/abstractions/secondment";

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    past: {
      type: Boolean,
      default: false,
    },
    started: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    STATUS_TYPE() {
      return STATUS_TYPE;
    },
    variant() {
      switch (this.status) {
        case STATUS_TYPE.CONFIRMED:
          return "label-light-primary";
        case STATUS_TYPE.CANCELLED:
          return "label-light-danger";
        case STATUS_TYPE.EDITED:
          return "label-light-info";
        case STATUS_TYPE.DRAFT:
          return "label-light-dark";
        case STATUS_TYPE.PROCESSING:
          return "label-light-warning";
        case STATUS_TYPE.COMPLETED:
          return "label-light-success";
        default:
          return "";
      }
    }
  }
};
</script>
