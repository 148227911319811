import API from "../api.service";

export default {
  get RISK_LEGEND_MAP() {
    return {
      1: {
        label: "Level A",
        color: "#16a085"
      },
      2: {
        label: "Level B",
        color: "#f1c40f"
      },
      3: {
        label: "Level C",
        color: "#f39c12"
      },
      4: {
        label: "Level D",
        color: "#c0392b"
      }
    };
  },

  /**
   * Get KML file url for a specific country.
   * @param {string} countryCode
   * @returns
   */
  async getCountryRiskMap(countryCode) {
    const r = await API.get("riskmaps/get_country_risk_map/", {
      params: {
        country: countryCode
      }
    });
    return r.data;
  },

  /**
   * Get risk for the given coordinates.
   * @param {string} countryCode
   * @param {number} latitute
   * @param {number} longitude
   * @returns
   */
  async getPlaceRiskLevel(countryCode, latitute, longitude, date = null) {
    const r = await API.get("riskmaps/get_place_risk_level/", {
      params: {
        country: countryCode,
        lat: latitute,
        lng: longitude,
        date
      },
    });
    return r.data;
  },

  async getAirQualityData({
    lat,
    lng
  }) {
    const r = await API.get("riskmaps/get_pollution_data/", {
      params: {
        lat,
        lng
      },
    });
    return r.data;
  },

  /**
   * Get risk for the route provided.
   * @param {string} countryCode
   * @param {Array} path array of {lat, lng} objects
   * @returns
   */
  async getRouteRiskLevel(countryCode, path, date = null) {
    const r = await API.get("riskmaps/get_route_risk_level/", {
      params: {
        country: countryCode,
        path,
        date
      },
    });
    return r.data;
  },

  /**
   * Get Procedure for the given risk level in the country.
   * @param {string} countryCode
   * @param {number} riskLevel
   */
  async getProcedures(countryCode, riskLevel) {
    const r = await API.get("riskmaps/get_procedures/", {
      params: {
        country: countryCode,
        risk_level: riskLevel
      },
    });
    return r.data;
  },

  async getCountryMaxRiskLevel(countryIso) {
    const r = await API.get("riskmaps/get_country_max_risk_level/", {
      params: {
        country: countryIso
      },
    });
    return r.data;
  },

  async getCountryPerAssessment(countryIso) {
    const r = await API.get("riskmaps/request_assessment/", {
      params: {
        selected_country: countryIso
      },
    });
    return r.data;
  },

  async requestCountryPerAssessment(countryIso) {
    return await API.post("riskmaps/request_assessment/", {
      params: {
        selected_country: countryIso,
        action: 'request_pre_assessment',
        assessment_kind: 'country'
      },
    });
  },
};