<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ !reportOnly ? $t("Active assignments") : $t("Risk Report") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("Your active assignments in the world")
        }}</span>
      </div>
      <div class="card-toolbar">
        <div v-if="!reportOnly && !isOnlyMaps">
          <a v-for="(filterBtn, idx) in filterList" :key="idx" class="btn ml-4"
            :class="filter == filterBtn.value ? 'btn-primary' : 'btn-secondary'" @click="filter = filterBtn.value">
            {{ $t(filterBtn.tag) }}
          </a>
        </div>
        <div v-if="reportOnly" class="d-flex align-items-center">
          <span class="mr-2">{{ $t('Country') }}</span>
          <euro-select v-model="selectedCountry" :options="countriesSelectable" searchable :style="{ maxWidth: '300px' }"
            class-name="mb-0 min-w-300px"></euro-select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <b-row>
        <b-col cols="12" lg="12" xl="12" class="map-container">
          <secondments-map-chart :routes="routes" :center="center" :selected-country.sync="selectedCountry"
            :highlighted-countries="countriesToHighlight" :countries-sub="getCountrySubscription" height=700
            @toggle-statistics="toggleStatistics" @toggle-search="toggleSearch">
          </secondments-map-chart>
          <div class="statistics-wrapper">
            <div class="statistics-container card mb-2" :class="{ 'hidden': searchHidden }">
              <euro-select v-model="selectedCountry" :options="countriesSelectable" searchable
                :style="{ maxWidth: '100%', minWidth: '280px' }" :placeholder="$t('Country')"
                class-name="mb-0"></euro-select>
            </div>
            <div class="statistics-container card" :class="{ 'hidden': isHidden }">
              <country-statistics-card horizontal card :b-card-bindings="{ 'border-variant': 'transparent' }"
                :country-iso="selectedCountry" :home-country-iso="homeCountryIso" :active-subs="getActiveSubscription"
                :pending-subs="getPendingCapabilities" :show-empty-message="true" :compact="true"
                @update-selected-color="handleUpdateSelectedColor" @update-capabilities="$emit('update-capabilities')"
                @go-to-shop="(e) => $emit('go-to-shop', e)">
              </country-statistics-card>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import SecondmentsMapChart from "@/view/components/charts/SecondmentsMapChart.vue";
import CountryStatisticsCard from "@/view/components/cards/CountryStatisticsCard.vue";

import CompanyService from "@/core/services/company/company.service";
import SecondmentService from "@/core/services/secondment/secondment.service";
import DateService from "@/core/services/date.service";

import moment from "moment";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    SecondmentsMapChart,
    CountryStatisticsCard,
  },
  props: {
    reportOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      secondments: [],
      filter: "active",
      isLoading: false,
      selectedCountry: "",
      address: null,
      countriesToHighlight: [],
      isHidden: false,
      searchHidden: true,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapState("user", ["expandedMainAddress"]),
    ...mapGetters("user", ["getCurrentManagedCompany", "isOnlyMaps"]),
    ...mapGetters("capabilities", ["getActiveSubscription", "getPendingCapabilities", "getCountrySubscription"]),
    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    activeFilterLabel() {
      return this.filterList.find((el) => el.value === this.filter)?.tag;
    },
    filterList() {
      return [
        {
          value: "active",
          tag: this.$t("Active"),
          apiFilter: {
            date_end_gt: DateService.format(new Date()),
          },
        },
        {
          value: "closed_last_365",
          tag: this.$t("Closed in last 365 days"),
          apiFilter: {
            date_end_lt: DateService.format(new Date()),
            date_end_gt: DateService.format(moment().subtract(1, "year")),
          },
        },
        {
          value: "starting_in_7",
          tag: this.$t("Starting in 7 days"),
          apiFilter: {
            date_start_gt: DateService.format(new Date()),
            date_start_lt: DateService.format(moment().add(7, "days")),
          },
        },
        {
          value: "starting_in_30",
          tag: this.$t("Starting in 30 days"),
          apiFilter: {
            date_start_gt: DateService.format(new Date()),
            date_start_lt: DateService.format(moment().add(30, "days")),
          },
        },
        {
          value: "starting_in_365",
          tag: this.$t("Starting in 365 days"),
          apiFilter: {
            date_start_gt: DateService.format(new Date()),
            date_start_lt: DateService.format(moment().add(1, "year")),
          },
        },
      ];
    },

    routes() {
      if (this.reportOnly || !this.center) return [];
      const centerArr = [this.center.longitude, this.center.latitude];
      const out = [];

      for (const secondment of this.secondments) {
        for (const client of secondment.clients) {
          if (!client.address.latitude || !client.address.longitude) continue;
          const risk = client.risk_logs[0]?.risk_status?.risk;
          let color;
          if (risk === 1) {
            color = "#61ff61";
          } else if (risk === 2) {
            color = "#ffd500";
          } else if (risk === 3) {
            color = "#ff6f00";
          } else if (risk === 4) {
            color = "#ff0000";
          } else {
            color = "#e7e7e7"; // Default color for unknown risk values
          }
          const route = {
            geometry: {
              type: "LineString",
              coordinates: [centerArr, [client.address.longitude, client.address.latitude]],
            },
            clientName: client.name,
            color: color,
          };
          out.push(route);
        }
      }

      return out;
    },

    center() {
      return {
        latitude: this.address?.address?.latitude,
        longitude: this.address?.address?.longitude,
      };
    },

    homeCountryIso() {
      return this.address?.address?.locality?.state?.country?.code;
    },
  },

  watch: {
    filter() {
      this.isLoading = true;
      this.loadSecondments().finally(() => (this.isLoading = false));
    },
    selectedCountry() {
      this.isHidden = false;
    }
  },

  async beforeMount() {
    if (this.reportOnly) return;
    this.isLoading = true;
    try {
      await Promise.all([this.loadCompanyAddress(), this.loadSecondments()]);
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    handleUpdateSelectedColor(risk_level) {
      // Emit a custom event to be listened by the SecondmentsMapChart component.
      this.$emit('update-color-in-map', risk_level);
    },
    toggleStatistics() {
      this.isHidden = !this.isHidden;
    },
    toggleSearch() {
      this.searchHidden = !this.searchHidden;
    },
    async loadCompanyAddress() {
      this.address = this.expandedMainAddress;
    },

    async loadSecondments() {
      if (this.reportOnly) return;

      const filter = this.filterList.find((f) => f.value == this.filter);

      await SecondmentService.get({
        perPage: 100,
        page: 1,
        secondmentStatus: ["confirmed", "completed"],
        ...filter.apiFilter,
        omit: 'travel_plans',
        fields: 'clients',
        expand: 'clients.address',
      }).then(response => {
        this.secondments = response.data.results;
      }).catch(err => {
        console.error('@loadSecondments', err.response.data);
      });
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-item.active {
  color: #fff;
  background-color: var(--primary);
}

.map-container {
  position: relative;
}

.statistics-wrapper {
  position: absolute;
  top: 45px;
  left: 10px;
}

.statistics-container {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  padding: 10px;
  margin-left: 5px;
  /* Make space for the toggle icon */
  max-width: 300px;
}

.toggle-icon {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  font-size: 20px;
  z-index: 10;
  /* Make sure the icon stays on top */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}
</style>
