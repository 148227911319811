var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.countryName ? _c('div', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.countryName) + " "), _c('i', {
    staticClass: "risk-label",
    class: [_vm.icons.infoCircle]
  })]), _c('div', {
    staticClass: "text-uppercase text-dark-70 risk-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Showing risks from")) + " " + _vm._s(_vm.currentOrFutureDateFormatted) + " ")])]) : _c('div', [_c('div', {
    staticClass: "alert alert-secondary d-inline-block text-uppercase text-dark-70",
    attrs: {
      "role": "alert"
    }
  }, [_c('i', {
    staticClass: "risk-label",
    class: [_vm.icons.infoCircle]
  }), _vm._v(" " + _vm._s(_vm.$t("Showing risks from")) + " " + _vm._s(_vm.currentOrFutureDateFormatted) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }