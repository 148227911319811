<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("Next 10 scheduled assignments") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("Show your next assignments approved") }}</span>
      </div>
    </div>
    <b-overlay :show="isLoading" rounded="sm">
      <div v-if="nextSecondments.length > 0" class="card-body detail pt-4">
        <b-table :items="nextSecondments" :fields="fields" :per-page="perPage" :current-page="currentPage" responsive
          :busy="isLoading">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-3"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(dateStart)="{ item }">
            {{ item.dateStart ? DateService.format(item.dateStart, dateFormat) : "" }}
          </template>

          <template #cell(dateEnd)="{ item }">
            {{ item.dateStart ? DateService.format(item.dateEnd, dateFormat) : "" }}
          </template>

          <template #cell(nation)="{ item }">
            <div class="d-flex align-items-center">
              <CountryFlag :country-iso="item.nation.iso">
              </CountryFlag>
            </div>
          </template>
          <template #cell(client)="{ item }">
            <div class="d-flex align-items-center">
              <Avatar :avatar-image="item.client.logo" :avatar-text="item.client.initials" class="mr-2" />
              <div class="d-flex flex-column">
                <span>{{ item.client.name }}</span>
              </div>
            </div>
          </template>
          <template #cell(pregress)="{ item }">
            <secondment-status-badge size="sm" :status="item.status" label-only></secondment-status-badge>
          </template>
          <template #cell(action)="{ item }">
            <b-button tooltip="See Overview" class="btn btn-icon btn-light btn-hover-primary btn-sm"
              @click="showDetail(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
              </span>
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
        </div>
      </div>
      <div v-else class="text-center p-40">
        <h3>
          <i class=" flaticon2-list-3 text-primary svg-icon-lg" style="font-size: 2.4rem;"></i>
          {{ $t("No scheduled assignments") }}
        </h3>
        <span class="text-muted" style="font-size: 1.2rem;">
          {{ $t("You will need to create an ASSIGNMENT to view the scheduled assignments table.") }}</span>
        <div class="pt-5 pb-4">
          <router-link :to="{ name: 'create-secondment' }" class="btn btn-light-primary font-weight-bolder">
            <div class="d-flex">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
              </span>
              {{ $t("Create Assignment") }}
            </div>
          </router-link>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import SecondmentStatusBadge from "@/view/components/badges/SecondmentStatusBadge.vue";
export default {
  components: {
    SecondmentStatusBadge,
    CountryFlag,
  },

  props: {
    nextSecondments: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      DateService,
      currentPage: 1,
      perPage: 3,
      fields: [
        {
          key: "nation",
          label: `${this.$t("Nation")}`,
          sortable: true,
          class: ["table-align text-nowrap", "font-weight-bolder"],
        },
        {
          key: "client",
          label: `${this.$t("Client")}`,
          sortable: true,
          class: ["table-align text-nowrap", "font-weight-bolder"],
        },
        {
          key: "dateStart",
          label: `${this.$t("Start date")}`,
          sortable: true,
          class: ["table-align text-nowrap", "font-weight-bolder"],
        },
        {
          key: "dateEnd",
          label: `${this.$t("End date")}`,
          sortable: true,
          class: ["table-align text-nowrap", "font-weight-bolder"],
        },
        {
          key: "pregress",
          label: `${this.$t("Status")}`,
          sortable: true,
          class: ["table-align text-nowrap", "font-weight-bolder"],
        },
        { key: "action", label: "Actions", class: "align-end col-actions" },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["dateFormat"]),

    rows() {
      return this.nextSecondments.length;
    },
  },
  methods: {
    showDetail(item) {
      this.$router.push({ name: "detail-secondment", params: { secondmentId: item.id } });
    },
  },
};
</script>

<style scoped>
::v-deep .table-align {
  vertical-align: middle;
}

.status-label {
  width: 12ch;
  text-align: center;
}
</style>
