var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 py-5 align-items-center"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignment days check for declaration")) + " ")])]), _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_vm.items && _vm.items.length > 0 ? _c('div', {
    staticClass: "card-body detail pt-0"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.items,
      "fields": _vm.fields,
      "thead-class": "hidden_header",
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(employee)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [item.employee.avatar ? _c('div', {
          staticClass: "mr-2"
        }, [_c('Avatar', {
          staticClass: "mr-2",
          attrs: {
            "avatar-image": item.employee.avatar
          }
        })], 1) : _c('div', {
          staticClass: "mr-2"
        }, [_c('Avatar', {
          staticClass: "mr-2",
          attrs: {
            "avatar-text": item.employee.name.split(' ').map(function (n) {
              return n[0];
            }).join('')
          }
        })], 1), _c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('span', [_vm._v(_vm._s(item.employee.name))])])])];
      }
    }, {
      key: "cell(days)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('CountryFlag', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": item.nation.name || 'unknown',
            "country-iso": item.nation.iso
          }
        }), _c('span', {
          staticClass: "ml-2 font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item.nation.name) + " ")])], 1), _c('div', {
          staticClass: "progress-wrapper",
          staticStyle: {
            "position": "relative",
            "height": "2rem"
          }
        }, [_c('b-progress', {
          attrs: {
            "max": item.nation.max,
            "height": "2rem"
          }
        }, [_c('b-progress-bar', {
          attrs: {
            "value": item.totalDays
          }
        })], 1), _c('div', {
          staticStyle: {
            "position": "absolute",
            "top": "50%",
            "left": "50%",
            "transform": "translate(-50%, -50%)",
            "width": "100%",
            "text-align": "center"
          }
        }, [_c('span', [_vm._v("Days: "), _c('strong', [_vm._v(_vm._s(item.totalDays) + " / " + _vm._s(item.nation.max))])])])], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.totalDays >= item.nation.max ? _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('i', {
          staticClass: "fas fa-exclamation text-danger mr-2",
          attrs: {
            "title": _vm.$t('declaration needed')
          }
        })]) : _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('i', {
          staticClass: "fas fa-check text-success mr-2",
          attrs: {
            "title": _vm.$t('No declaration needed')
          }
        })])];
      }
    }], null, false, 2182637813)
  })], 1) : _c('div', {
    staticClass: "p-4 text-center"
  }, [_c('h3', [_c('i', {
    staticClass: "flaticon2-graphic-1 text-primary svg-icon-lg",
    staticStyle: {
      "font-size": "2.4rem"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("No data")) + " ")]), _c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("You will need to create an ASSIGNMENT to view this graph.")))]), _c('div', {
    staticClass: "pt-5 mb-7"
  }, [_c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'create-secondment'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Tools/Compass.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create Assignment")) + " ")])])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }