<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("Last Updates") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("All countries data recent updates") }}</span>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex"
        :class="{ 'flex-row flex-wrap justify-content-between': horizontal, 'flex-column': !horizontal }">
        <!--begin::Item-->
        <div v-for="(item, i) in getRiskLevel(horizontal ? 10 : 4)" :key="i"
          class="bg-light-secondary rounded p-5 mb-5 cursor-default" :class="{ 'max-width-alert': horizontal }">
          <div class="d-flex align-items-center updates-chip">
            <div class="mr-4">
              <CountryFlag :country-iso="item.data.target_object_id"></CountryFlag>
            </div>
            <div>
              <div class="font-weight-bolder text-dark-75 font-size-xs text-capitalize">
                <span v-if="item.data.target_representation != 'None'">
                  {{ item.data.target_representation }}
                </span>
                <span v-else>
                  {{ item.data.actor_representation }}
                </span>
                <span v-if="item.data.data?.kind === 'risk_map_update'">
                  {{ $t("Risk Map updated") }}
                </span>
                <span v-else>
                  {{ $t("Report updated") }}
                </span>
              </div>
              <span v-b-tooltip.hover class="updates-chip-date"
                    :title="DateService.format(item.data.timestamp, dateFormat + ' HH:mm:ss')">{{
                  DateService.timeAgo(item.data.timestamp)
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";

export default {
  components: {
    CountryFlag,
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      DateService,
    };
  },
  computed: {
    ...mapGetters("notification", ["getRiskLevel"]),
    ...mapGetters("user", ["dateFormat"]),
  },
};
</script>

<style>
.max-width-alert {
  flex: 0 0 19%;
}

.updates-chip {
  position: relative;
}

.updates-chip-date {
  position: absolute;
  bottom: -0.9rem;
  right: 0.1rem;
  font-size: 0.8em;
  color: #888;
}

</style>