<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5 align-items-center">
      <h3 class="card-title">
        {{ $t("Assignments distribution by country") }}
      </h3>
    </div>
    <b-overlay :show="isLoading" rounded="sm">
      <div v-if="seriesArray.length > 0" class="card-toolbar m-0">
        <div>
          <apexchart class="card-rounded-bottom mb-4" :options="chartOptions" :series="series" type="donut"></apexchart>
        </div>
      </div>
      <div v-else class="p-4 text-center">
        <h3>
          <i class="flaticon2-graphic-1 text-primary svg-icon-lg" style="font-size: 2.4rem;"></i>
          {{ $t("No data") }}
        </h3>
        <span class="text-muted" style="font-size: 1.2rem;">
          {{ $t("You will need to create an ASSIGNMENT to view this graph.") }}</span>
        <div class="pt-5 mb-7">
          <router-link :to="{ name: 'create-secondment' }" class="btn btn-light-primary font-weight-bolder">
            <div class="d-flex">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
              </span>
              {{ $t("Create Assignment") }}
            </div>
          </router-link>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    secondmentsDistribution: {
      type: [Object],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      root: null,
      legend: null,
      chart: null,
      chartOptions: {},
      series: [],
      labels: []
    };
  },
  computed: {
    ...mapGetters("constants", ["countriesMap"]),
    seriesArray() {
      return this.series;
    },
  },
  watch: {
    secondmentsDistribution(val) {
      this.loadSeries(val);
    },
  },
  mounted() {
    // Create root and chart
    this.chartOptions = {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: false,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        horizontalAlign: 'center',
        position: 'bottom'
      },
      labels: this.labels
    };
    this.loadSeries(this.secondmentsDistribution);
  },

  methods: {
    loadSeries(nations) {
      this.series = []

      for (const value of Object.values(nations)) {
        this.series.push(value.count);
        this.labels.push(value.country_name);
      }
    }
  },
};
</script>

<style scoped>
#secondmentsChart {
  width: 100%;
  height: 400px;
}

#secondmentsLegendwrapper {
  max-height: 120px;
  overflow-y: auto;
}
</style>
