var render = function render(){
  var _vm$countriesMap$_vm$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "country-flag",
    class: {
      big: _vm.big,
      medium: _vm.medium
    },
    attrs: {
      "title": _vm.$t("".concat((_vm$countriesMap$_vm$ = _vm.countriesMap[_vm.countryIso]) === null || _vm$countriesMap$_vm$ === void 0 ? void 0 : _vm$countriesMap$_vm$.name)),
      "src": "/media/flags/".concat(_vm.countryIso.toLowerCase(), ".svg")
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }