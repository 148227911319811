<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5 align-items-center">
      <h3 class="card-title">
        {{ $t("Assignment days check for declaration") }}
      </h3>
    </div>
    <b-overlay :show="isLoading" rounded="sm">
      <div v-if="items && items.length > 0" class="card-body detail pt-0">
        <b-table :items="items" :fields="fields" thead-class="hidden_header" responsive>
          <template #cell(employee)="{ item }">
            <div class="d-flex align-items-center">
              <div v-if="item.employee.avatar" class="mr-2">
                <!-- Render avatar image when it exists -->
                <Avatar :avatar-image="item.employee.avatar" class="mr-2" />
              </div>
              <div v-else class="mr-2">
                <!-- Render initials when avatar image doesn't exist -->
                <Avatar :avatar-text="item.employee.name.split(' ').map(n => n[0]).join('')" class="mr-2" />
              </div>
              <div class="d-flex flex-column">
                <span>{{ item.employee.name }}</span>

              </div>
            </div>
          </template>
          <template #cell(days)="{ item }">
            <div class="d-flex align-items-center mb-2">
              <CountryFlag v-b-tooltip.hover :title="item.nation.name || 'unknown'" :country-iso="item.nation.iso">
              </CountryFlag>
              <span class="ml-2 font-weight-bolder">
                {{ item.nation.name }}
              </span>
            </div>
            <div class="progress-wrapper" style="position: relative; height: 2rem;">
              <!-- Progress Bar -->
              <b-progress :max="item.nation.max" height="2rem">
                <b-progress-bar :value="item.totalDays"></b-progress-bar>
              </b-progress>

              <!-- Text centered above the progress bar -->
              <div
                style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; text-align: center;">
                <span>Days: <strong>{{ item.totalDays }} / {{ item.nation.max }}</strong></span>
              </div>
            </div>
          </template>
          <template #cell(status)="{ item }">
            <div v-if="item.totalDays >= item.nation.max" class="d-flex align-items-center">
              <i class="fas fa-exclamation text-danger mr-2" :title="$t('declaration needed')"></i>
            </div>
            <div v-else class="d-flex align-items-center">
              <i class="fas fa-check text-success mr-2" :title="$t('No declaration needed')"></i>
            </div>
          </template>
        </b-table>
      </div>
      <div v-else class="p-4 text-center">
        <h3>
          <i class="flaticon2-graphic-1 text-primary svg-icon-lg" style="font-size: 2.4rem;"></i>
          {{ $t("No data") }}
        </h3>
        <span class="text-muted" style="font-size: 1.2rem;">
          {{ $t("You will need to create an ASSIGNMENT to view this graph.") }}</span>
        <div class="pt-5 mb-7">
          <router-link :to="{ name: 'create-secondment' }" class="btn btn-light-primary font-weight-bolder">
            <div class="d-flex">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
              </span>
              {{ $t("Create Assignment") }}
            </div>
          </router-link>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";

export default {
  components: {
    CountryFlag
  },
  props:
  {
    employeesDaysForDeclarationSecondments: {
      type: Object,
      default: () => { },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "employee", label: `${this.$t("Employee")}`, class: ["table-align"] },
        { key: "days", label: `${this.$t("Days")}`, class: ["table-align"] },
        { key: "status", label: `${this.$t("Status")}`, class: ["table-align text-center"] },
      ],
    };
  },

  computed: {
    items() {
      const items = [];

      for (const employeeId in this.employeesDaysForDeclarationSecondments) {
        const employee = this.employeesDaysForDeclarationSecondments[employeeId];

        for (const secondmentDay of employee.secondment_days) {
          items.push({
            employee: {
              name: employee.name,
              id: employee.id,
              avatar: employee.avatar,
            },
            totalDays: secondmentDay.days,
            nation: {
              name: secondmentDay.country.name, // or get country name from country code, if necessary
              iso: secondmentDay.country.iso,
              days: secondmentDay.days,
              max: 180,
            },
            status: "", // you can populate status accordingly
          });
        }
      }

      // Sorting the items by the number of days
      items.sort((a, b) => b.nation.days - a.nation.days);

      // Return only the first 5 items for now
      return items.slice(0, 5);
    }
  }
};
</script>

<style scoped>
::v-deep .progress-days {
  width: 100%;
  min-width: 150px;
}

::v-deep .table-align {
  vertical-align: middle;
}

::v-deep .hidden_header {
  display: none;
}
</style>
