<template>
  <div>
    <div v-if="countryName">
      <h4 class="mb-2">
        {{ countryName }}
        <i class="risk-label" :class="[icons.infoCircle]"></i>
      </h4>
      <div class="text-uppercase text-dark-70 risk-label">
        {{ $t("Showing risks from") }} {{ currentOrFutureDateFormatted }}
      </div>
    </div>
    <div v-else>
      <div class="alert alert-secondary d-inline-block text-uppercase text-dark-70" role="alert">
        <i class="risk-label" :class="[icons.infoCircle]"></i>
        {{ $t("Showing risks from") }} {{ currentOrFutureDateFormatted }}
      </div>
    </div>
  </div>
</template>

<script>
// import EuroAlert from "@/view/components/alerts/EuroAlert.vue";
import icons from "@/core/config/icons";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  // components: {
  //   EuroAlert,
  // },

  props: {
    date: {
      type: String,
      required: true,
    },
    countryName: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      icons,
      DateService,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),

    isCurrentDayInFuture() {
      return DateService.isBefore(moment(this.date, this.dateFormat), moment());
    },

    currentOrFutureDateFormatted() {
      return this.isCurrentDayInFuture ? this.date : DateService.format(new Date(), this.dateFormat);
    }
  },
};
</script>

<style scoped>
.risk-label {
  font-size: 12px;
}
</style>
