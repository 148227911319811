import api from "../api.service";

class NationCurrencies {
  async getAll() {
    const res = await api.get("nations-currencies");
    return res.data;
  }

  async getAllCurrencies(paginated = true, limit = 10, offset = 0, search = '') {
    const res = await api.get("nations-currencies/", {
      params: {
        paginated: paginated,
        limit: limit,
        offset: offset,
        search: search
      }
    });
    return res.data;
  }

  async exchangeRate(fromCurrency, toCurrency) {
    const res = await api.get("nations-currencies/currency_exchange/", {
      params: {
        base_currency: fromCurrency,
        currency: toCurrency,
      },
    });
    return res.data;
  }
}

export default new NationCurrencies();