var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder text-dark"
  }, [_vm._v(" " + _vm._s(!_vm.reportOnly ? _vm.$t("Active assignments") : _vm.$t("Risk Report")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("Your active assignments in the world")))])]), _c('div', {
    staticClass: "card-toolbar"
  }, [!_vm.reportOnly && !_vm.isOnlyMaps ? _c('div', _vm._l(_vm.filterList, function (filterBtn, idx) {
    return _c('a', {
      key: idx,
      staticClass: "btn ml-4",
      class: _vm.filter == filterBtn.value ? 'btn-primary' : 'btn-secondary',
      on: {
        "click": function click($event) {
          _vm.filter = filterBtn.value;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(filterBtn.tag)) + " ")]);
  }), 0) : _vm._e(), _vm.reportOnly ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.$t('Country')))]), _c('euro-select', {
    style: {
      maxWidth: '300px'
    },
    attrs: {
      "options": _vm.countriesSelectable,
      "searchable": "",
      "class-name": "mb-0 min-w-300px"
    },
    model: {
      value: _vm.selectedCountry,
      callback: function callback($$v) {
        _vm.selectedCountry = $$v;
      },
      expression: "selectedCountry"
    }
  })], 1) : _vm._e()])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "map-container",
    attrs: {
      "cols": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('secondments-map-chart', {
    attrs: {
      "routes": _vm.routes,
      "center": _vm.center,
      "selected-country": _vm.selectedCountry,
      "highlighted-countries": _vm.countriesToHighlight,
      "countries-sub": _vm.getCountrySubscription,
      "height": "700"
    },
    on: {
      "update:selectedCountry": function updateSelectedCountry($event) {
        _vm.selectedCountry = $event;
      },
      "update:selected-country": function updateSelectedCountry($event) {
        _vm.selectedCountry = $event;
      },
      "toggle-statistics": _vm.toggleStatistics,
      "toggle-search": _vm.toggleSearch
    }
  }), _c('div', {
    staticClass: "statistics-wrapper"
  }, [_c('div', {
    staticClass: "statistics-container card mb-2",
    class: {
      'hidden': _vm.searchHidden
    }
  }, [_c('euro-select', {
    style: {
      maxWidth: '100%',
      minWidth: '280px'
    },
    attrs: {
      "options": _vm.countriesSelectable,
      "searchable": "",
      "placeholder": _vm.$t('Country'),
      "class-name": "mb-0"
    },
    model: {
      value: _vm.selectedCountry,
      callback: function callback($$v) {
        _vm.selectedCountry = $$v;
      },
      expression: "selectedCountry"
    }
  })], 1), _c('div', {
    staticClass: "statistics-container card",
    class: {
      'hidden': _vm.isHidden
    }
  }, [_c('country-statistics-card', {
    attrs: {
      "horizontal": "",
      "card": "",
      "b-card-bindings": {
        'border-variant': 'transparent'
      },
      "country-iso": _vm.selectedCountry,
      "home-country-iso": _vm.homeCountryIso,
      "active-subs": _vm.getActiveSubscription,
      "pending-subs": _vm.getPendingCapabilities,
      "show-empty-message": true,
      "compact": true
    },
    on: {
      "update-selected-color": _vm.handleUpdateSelectedColor,
      "update-capabilities": function updateCapabilities($event) {
        return _vm.$emit('update-capabilities');
      },
      "go-to-shop": function goToShop(e) {
        return _vm.$emit('go-to-shop', e);
      }
    }
  })], 1)])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }