var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 py-5 align-items-center"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignments days by country")) + " ")]), _c('div', {
    staticClass: "card-toolbar m-0"
  }, _vm._l(_vm.filters, function (filter) {
    return _c('a', {
      key: filter.value,
      staticClass: "btn mr-4",
      class: _vm.filterValue == filter.value ? 'btn-primary' : 'btn-secondary',
      on: {
        "click": function click($event) {
          return _vm.$emit('update:filterValue', filter.value);
        }
      }
    }, [_vm._v(" " + _vm._s(filter.text) + " ")]);
  }), 0)]), _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_vm.nationsSecondments && _vm.nationsSecondments.length > 0 ? _c('div', {
    staticClass: "card-body detail pt-0"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.nationsSecondments,
      "fields": _vm.fields,
      "busy": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-primary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-3"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t("loading...")))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(highest)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "mr-6"
        }, [_c('i', {
          staticClass: "pointer",
          class: [data.detailsShowing ? _vm.icons.caretUp : _vm.icons.caretDown],
          on: {
            "click": data.toggleDetails
          }
        })]), _c('div', {
          staticClass: "w-100 pr-8"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-2"
        }, [_c('CountryFlag', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": data.item.nation.name || 'unknown',
            "country-iso": data.item.nation.iso
          }
        }), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(data.item.nation.name))])], 1), _c('div', {
          staticClass: "progress-wrapper",
          staticStyle: {
            "position": "relative",
            "height": "2rem"
          }
        }, [_c('b-progress', {
          attrs: {
            "max": "365",
            "height": "2rem"
          }
        }, [_c('b-progress-bar', {
          attrs: {
            "value": data.item.highest
          }
        })], 1), _c('div', {
          staticStyle: {
            "position": "absolute",
            "top": "50%",
            "left": "50%",
            "transform": "translate(-50%, -50%)",
            "width": "100%",
            "text-align": "center"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("Days")) + ": "), _c('strong', [_vm._v(" " + _vm._s(data.item.highest))])])])], 1)])])];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-table', {
          attrs: {
            "items": row.item.secondments,
            "fields": _vm.nestedFields
          },
          scopedSlots: _vm._u([{
            key: "cell(date_start)",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_vm._v(" " + _vm._s(item.date_start ? _vm.DateService.format(item.date_start, _vm.dateFormat) : "") + " ")];
            }
          }, {
            key: "cell(date_end)",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(item.date_start ? _vm.DateService.format(item.date_end, _vm.dateFormat) : "") + " ")];
            }
          }, {
            key: "cell(secondment)",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('Avatar', {
                staticClass: "mr-2",
                attrs: {
                  "avatar-image": item.logo,
                  "avatar-text": item.client_name[0]
                }
              }), _c('div', {
                staticClass: "d-flex flex-column"
              }, [_c('span', [_vm._v(_vm._s(item.client_name))])])], 1)];
            }
          }, {
            key: "cell(status)",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('span', {
                staticClass: "label label-lg label-inline label-rounded font-weight-bolder text-capitalize",
                class: item.status === 'confirmed' ? 'label-light-primary' : 'label-light-warning'
              }, [_vm._v(" " + _vm._s(item.status) + " ")])];
            }
          }], null, true)
        })];
      }
    }], null, false, 2313748582)
  })], 1) : _c('div', {
    staticClass: "p-4 text-center"
  }, [_c('h3', [_c('i', {
    staticClass: "flaticon2-graphic-1 text-primary svg-icon-lg",
    staticStyle: {
      "font-size": "2.4rem"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("No data")) + " ")]), _c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.filterDescription))]), _vm.filterValue !== 'last12Months' && _vm.filterValue !== 'next12Months' ? _c('div', {
    staticClass: "pt-5 mb-7"
  }, [_c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'create-secondment'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Tools/Compass.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create Assignment")) + " ")])])], 1) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }