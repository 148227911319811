var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t("Last Updates")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("All countries data recent updates")))])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex",
    class: {
      'flex-row flex-wrap justify-content-between': _vm.horizontal,
      'flex-column': !_vm.horizontal
    }
  }, _vm._l(_vm.getRiskLevel(_vm.horizontal ? 10 : 4), function (item, i) {
    var _item$data$data;
    return _c('div', {
      key: i,
      staticClass: "bg-light-secondary rounded p-5 mb-5 cursor-default",
      class: {
        'max-width-alert': _vm.horizontal
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center updates-chip"
    }, [_c('div', {
      staticClass: "mr-4"
    }, [_c('CountryFlag', {
      attrs: {
        "country-iso": item.data.target_object_id
      }
    })], 1), _c('div', [_c('div', {
      staticClass: "font-weight-bolder text-dark-75 font-size-xs text-capitalize"
    }, [item.data.target_representation != 'None' ? _c('span', [_vm._v(" " + _vm._s(item.data.target_representation) + " ")]) : _c('span', [_vm._v(" " + _vm._s(item.data.actor_representation) + " ")]), ((_item$data$data = item.data.data) === null || _item$data$data === void 0 ? void 0 : _item$data$data.kind) === 'risk_map_update' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("Risk Map updated")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("Report updated")) + " ")])]), _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "updates-chip-date",
      attrs: {
        "title": _vm.DateService.format(item.data.timestamp, _vm.dateFormat + ' HH:mm:ss')
      }
    }, [_vm._v(_vm._s(_vm.DateService.timeAgo(item.data.timestamp)))])])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }