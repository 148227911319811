<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5 align-items-center">
      <h3 class="card-title">
        {{ $t("Assignments days by country") }}
      </h3>
      <div class="card-toolbar m-0">
        <a v-for="filter in filters" :key="filter.value" class="btn mr-4"
          :class="filterValue == filter.value ? 'btn-primary' : 'btn-secondary'"
          @click="$emit('update:filterValue', filter.value)">
          {{ filter.text }}
        </a>
      </div>
    </div>
    <b-overlay :show="isLoading" rounded="sm">
      <div v-if="nationsSecondments && nationsSecondments.length > 0" class="card-body detail pt-0">
        <b-table :items="nationsSecondments" :fields="fields" :busy="isLoading">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-3"></b-spinner>
              <strong>{{ $t("loading...") }}</strong>
            </div>
          </template>

          <template #cell(highest)="data">
            <div class="d-flex align-items-center">
              <div class="mr-6">
                <i class="pointer" :class="[data.detailsShowing ? icons.caretUp : icons.caretDown]"
                  @click="data.toggleDetails"></i>
              </div>
              <div class="w-100 pr-8">
                <div class="d-flex align-items-center mb-2">
                  <CountryFlag v-b-tooltip.hover :title="data.item.nation.name || 'unknown'"
                    :country-iso="data.item.nation.iso"></CountryFlag>
                  <span class="ml-2">{{ data.item.nation.name }}</span>
                </div>
                <div class="progress-wrapper" style="position: relative; height: 2rem;">
                  <!-- Progress Bar -->
                  <b-progress max="365" height="2rem">
                    <b-progress-bar :value="data.item.highest"></b-progress-bar>
                  </b-progress>

                  <!-- Text centered above the progress bar -->
                  <div
                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; text-align: center;">
                    <span>{{ $t("Days") }}: <strong> {{ data.item.highest }}</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #row-details="row">
            <b-table :items="row.item.secondments" :fields="nestedFields">
              <template #cell(date_start)="{ item }">
                {{ item.date_start ? DateService.format(item.date_start, dateFormat) : "" }}
              </template>

              <template #cell(date_end)="{ item }">
                {{ item.date_start ? DateService.format(item.date_end, dateFormat) : "" }}
              </template>

              <template #cell(secondment)="{ item }">
                <div class="d-flex align-items-center">
                  <Avatar :avatar-image="item.logo" :avatar-text="item.client_name[0]" class="mr-2" />
                  <div class="d-flex flex-column">
                    <span>{{ item.client_name }}</span>
                  </div>
                </div>
              </template>

              <template #cell(status)="{ item }">
                <span class="label label-lg label-inline label-rounded font-weight-bolder text-capitalize"
                  :class="item.status === 'confirmed' ? 'label-light-primary' : 'label-light-warning'">
                  {{ item.status }}
                </span>
              </template>
            </b-table>
          </template>
        </b-table>
      </div>
      <div v-else class="p-4 text-center">
        <h3>
          <i class="flaticon2-graphic-1 text-primary svg-icon-lg" style="font-size: 2.4rem;"></i>
          {{ $t("No data") }}
        </h3>
        <span class="text-muted" style="font-size: 1.2rem;"> {{ filterDescription }}</span>
        <div v-if="filterValue !== 'last12Months' && filterValue !== 'next12Months'" class="pt-5 mb-7">
          <router-link :to="{ name: 'create-secondment' }" class="btn btn-light-primary font-weight-bolder">
            <div class="d-flex">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
              </span>
              {{ $t("Create Assignment") }}
            </div>
          </router-link>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import icons from "@/core/config/icons";
export default {
  components: {
    CountryFlag,
  },

  props: {
    nationsSecondments: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    filterValue: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icons,
      DateService,
      fields: [
        { key: "highest", label: "", class: ["font-weight-bolder"] },
      ],
      nestedFields: [
        { key: "secondment", label: `${this.$t("Client")}`, class: ["table-align", "font-weight-bolder"] },
        { key: "date_start", label: `${this.$t("Start date")}`, class: ["table-align", "font-weight-bolder"] },
        { key: "date_end", label: `${this.$t("End date")}`, class: ["table-align", "font-weight-bolder"] },
        { key: "status", label: `${this.$t("Status")}`, class: ["table-align", "font-weight-bolder"] },
      ],
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),

    activeFilter() {
      const selectedFilter = this.filters.find((el) => el.value === this.filterValue).text;
      return selectedFilter ? selectedFilter.text : `${this.$t("No data")}`;
    },
    filterText() {
      const activeFilterText = this.activeFilter;
      return activeFilterText || `${this.$t("No data")}`;
    },
    filterDescription() {
      if (this.filterValue === "last12Months") {
        return this.$t("There is no data for the last 12 months graph.");
      } else if (this.filterValue === "next12Months") {
        return this.$t("There is no data for the next 12 months graph.");
      } else {
        return this.$t("You will need to create an ASSIGNMENT to view this graph.");
      }
    },

    filters() {
      return [
        {
          value: "current",
          text: this.$t("Current"),
        },
        {
          value: "last12Months",
          text: this.$t("Last 12 months"),
        },
        {
          value: "next12Months",
          text: this.$t("Next 12 months"),
        },
      ];
    },
  },
};
</script>

<style scoped>
::v-deep .table-align {
  vertical-align: middle;
}
</style>
