var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-5"
  }, [_c('h3', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Documents Expiring")))])]), _c('div', {
    staticClass: "card-body d-flex flex-column"
  }, [_c('div', {
    staticClass: "flex-grow-1 text-center pt-5 font-size-h1 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.documentExpiring) + " ")]), _c('div', {
    staticClass: "pt-5"
  }, [_c('p', {
    staticClass: "text-center font-weight-normal font-size-lg pb-7"
  }, [_vm._v(" " + _vm._s(_vm.$t("List documents expiring 60 days")) + " ")]), _c('b-button', {
    staticClass: "btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3",
    attrs: {
      "to": {
        name: 'employees-documents',
        params: {
          expiring: 'true'
        }
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md svg-icon-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employees documents")) + " ")])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }