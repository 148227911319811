<template>
  <div ref="chartdiv" :style="`height: ${height}px`" class="chart"></div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import NaturalDisastersService from "@/core/services/natural-disasters.service";
// import RiskService from "@/core/services/risk/risk.service";

export default {
  props: {
    height: {
      type: [Number, String],
      default: 500,
    },
    routes: {
      type: Array,
      required: true,
    },
    center: {
      type: Object,
      default: null,
    },
    selectedCountry: {
      type: String,
      default: "",
    },
    highlightedCountries: {
      type: Array,
      default: () => [],
    },
    activeOrPendingSubs: {
      type: Object,
      default: () => { },
    },
    countriesSub: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected_country_risk_level: null,
      timeouts: [],
      isComponentActive: true,
      animations: [],
      destinations: [],
      planes_count: 0,
      eventsSeries: null,
      events: [],
    };
  },

  watch: {
    routes: {
      deep: true,
      handler() {
        this.planes_count = 0;
        this.destinations = [];
        this.makeCenter();
        this.makeLines();
        this.makeArrivals();
        this.makeMidPoints();
      },
    },

    selectedCountry() {
      this.selected_country_risk_level = null;
      this.colorCountries();
      this.zoomToCountry(this.selectedCountry);
    },

    highlightedCountries() {
      this.colorCountries();
    },

    countriesSub() {
      this.colorCountries();
    },
  },

  mounted() {
    let root = am5.Root.new(this.$refs.chartdiv);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        minZoomLevel: 1,
        maxZoomLevel: 6,
        pinchZoom: true,
        projection: am5map.geoNaturalEarth1(),
        wheelY: "none",
      })
    );

    chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

    let overlay = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layer: 100,
        visible: false,
      })
    );

    overlay.children.push(
      am5.Rectangle.new(root, {
        width: am5.p100,
        height: am5.p100,
        fill: am5.color(0x000000),
        fillOpacity: 0.3,
      })
    );

    overlay.children.push(
      am5.Label.new(root, {
        text: "Use CTRL + Scroll to zoom",
        fontSize: 30,
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
      })
    );

    chart.events.on("wheel", function (ev) {
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault();
        chart.set("wheelY", "zoom");
      } else {
        chart.set("wheelY", "none");
        overlay.show();
        overlay.setTimeout(function () {
          overlay.hide();
        }, 800);
      }
    });

    let worldDataCopy = JSON.parse(JSON.stringify(am5geodata_worldLow)); // Creating a deep copy

    worldDataCopy.features.forEach((feature) => {
      let countryName = feature.properties.name;
      feature.properties.name = this.$t(countryName);
    });

    let worldSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: worldDataCopy,
        exclude: ["AQ"],
      })
    );

    worldSeries.mapPolygons.template.states.create("selected", {
      fill: am5.color("#b2b2b2"),
      fillPattern: null,
    });

    worldSeries.mapPolygons.template.states.create("1", {
      fill: am5.color("#16a085"),
      fillPattern: null,
    });

    worldSeries.mapPolygons.template.states.create("2", {
      fill: am5.color("#f1c40f"),
      fillPattern: null,
    });

    worldSeries.mapPolygons.template.states.create("3", {
      fill: am5.color("#f39c12"),
      fillPattern: null,
    });

    worldSeries.mapPolygons.template.states.create("4", {
      fill: am5.color("#c0392b"),
      fillPattern: null,
    });

    worldSeries.mapPolygons.template.states.create("highlighted", {
      fill: am5.color("#32a852"),
      fillPattern: null,
    });

    worldSeries.mapPolygons.template.states.create("subscription", {
      fill: am5.color("#c9c9c9"),
      fillPattern: null,
    });

    worldSeries.mapPolygons.template.events.on("click", (ev) => {
      this.$emit("update:selectedCountry", ev.target.dataItem.dataContext.id);
    });

    worldSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      fill: am5.color("#e0e0e0"),
      templateField: "columnSettings",
      strokeWidth: 0.3,
      stroke: am5.color("#3b3b3b"),
      fillPattern: am5.LinePattern.new(root, {
        color: am5.color(0xffffff),
        width: 800,
        height: 800,
        colorOpacity: 0.9,
        rotation: 45,
        strokeWidth: 0.5,
      }),
    });

    this.chart = chart;
    this.worldSeries = worldSeries;

    this.root = root;

    if (this.routes.length && this.center) {
      this.isComponentActive = true;
      this.makeCenter();
      this.makeLines();
      this.makeArrivals();
      this.makeMidPoints();
    }

    // Add projection buttons
    var buttons = chart.children.push(
      am5.Container.new(root, {
        x: am5.p0,
        dx: -10,
        centerX: am5.p0,
        y: am5.p0,
        // dy: -10,
        centerY: am5.p0,
        layout: root.horizontalLayout,
        paddingTop: 5,
        paddingRight: 8,
        paddingBottom: 5,
        paddingLeft: 10,
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xffffff),
          fillOpacity: 0.3,
        }),
      })
    );

    function createButton(ComponentInstance, text, action) {
      let arrowIcon = action !== "statistics" ? " ▼" : " ▲";
      arrowIcon = action === "natural-disasters" ? " ☐" : arrowIcon;
      let button = buttons.children.push(
        am5.Button.new(root, {
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          marginLeft: 2,
          marginRight: 2,
          label: am5.Label.new(root, {
            text: text + arrowIcon,
          }),
        })
      );

      let isArrowUp = action === "statistics";

      button.events.on("click", () => {
        if (action === "statistics") {
          ComponentInstance.$emit("toggle-statistics");
          // Change the icon on click
          if (isArrowUp) {
            button.get("label").set("text", text + " ▼"); // Change the icon to arrow down
          } else {
            button.get("label").set("text", text + " ▲"); // Change the icon to arrow up
          }

          isArrowUp = !isArrowUp;
        }
        if (action === "search") {
          ComponentInstance.$emit("toggle-search");
          if (isArrowUp) {
            button.get("label").set("text", text + " ▼"); // Change the icon to arrow down
          } else {
            button.get("label").set("text", text + " ▲"); // Change the icon to arrow up
          }

          isArrowUp = !isArrowUp;
        }
        if (action === "natural-disasters") {
          // ComponentInstance.$emit("toggle-disasters"); TODO add filters
          if (!isArrowUp) {
            ComponentInstance.addNaturalDisastersAlerts();
            button.get("label").set("text", text + " ☒");
          } else {
            ComponentInstance.disposeNaturalDisastersAlerts();
            button.get("label").set("text", text + " ☐");
          }

          isArrowUp = !isArrowUp;
        }
      });
    }

    createButton(this, this.$t('Risk Information'), "statistics");
    createButton(this, this.$t('Search'), "search");
    createButton(this, this.$t('Natural Disasters'), "natural-disasters");

    this.$parent.$on("update-color-in-map", (risk_level) => {
      // Handle the received event on the parent component
      this.selected_country_risk_level = risk_level;
      this.colorCountries();
    });

    setTimeout(() => {
      this.colorCountries();
      // this.addNaturalDisastersAlerts();
    }, 1000)
  },

  beforeDestroy() {
    this.isComponentActive = false;
    this.timeouts.forEach(clearTimeout);
    this.animations.forEach((animation) => {
      animation.stop();
    });
    if (this.midPointsSeries) {
      this.midPointsSeries.dispose();
    }
    if (this.pointSeries) {
      this.pointSeries.dispose();
    }

    if (this.arrivalsSeries) {
      this.arrivalsSeries.dispose();
    }

    if (this.lineSeries) {
      this.lineSeries.dispose();
    }

    if (this.worldSeries) {
      this.worldSeries.dispose();
    }

    if (this.chart) {
      this.chart.dispose();
    }

    if (this.root) {
      this.root.dispose();
    }
    if (this.destinations) {
      this.destinations = [];
    }
    this.planes_count = 0;
  },

  methods: {
    makeCenter() {
      if (this.pointSeries) {
        this.pointSeries.dispose();
      }

      if (!this.root || !this.chart || !this.center) return;
      const pointSeries = this.chart.series.push(
        am5map.MapPointSeries.new(this.root, {})
      );
      const root = this.root;

      pointSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 7,
            fill: am5.color(0xffba00),
            stroke: root.interfaceColors.get("background"),
            strokeWidth: 2,
          }),
        });
      });

      pointSeries.pushDataItem({
        latitude: this.center.latitude,
        longitude: this.center.longitude,
      });

      this.pointSeries = pointSeries;
    },

    makeMidPoints() {
      if (this.midPointsSeries) {
        this.midPointsSeries.dispose();
        this.animations.forEach((animation) => {
          animation.stop();
        });
        this.timeouts.forEach(clearTimeout);
        this.midPointsSeries.data.setAll([]);
      }

      if (!this.root || !this.chart || !this.routes.length) return;

      const root = this.root;
      const midPoints = this.chart.series.push(
        am5map.MapPointSeries.new(root, {})
      );

      midPoints.bullets.push(function (root) {
        const plane = am5.Container.new(root, {});
        plane.children.push(
          am5.Graphics.new(root, {
            //eslint-disable-next-line
            svgPath: "m 32.072344,0.46772168 -1.102487,0.96885202 -0.935445,2.1715648 -1.00226,4.1092691 -0.200451,1.4365737 0.03341,12.6953027 -4.777444,3.30746 0.200452,-0.517835 0.11693,-0.818513 v -2.054634 l -0.133634,-0.902035 -0.200452,-0.434313 -3.173826,0.01671 -0.200452,0.534539 -0.167044,1.386461 0.0167,1.937704 0.367495,1.286234 0.367496,0.03341 0.217156,0.818513 0.183748,0.31738 -20.3458924,13.8312 -1.25282593,2.438833 0.01670433,0.851922 2.6225822,-1.820773 10.0560158,-3.958931 0.100226,0.484426 0.300678,-0.03341 0.116931,-0.684877 4.777443,-1.570209 0.233861,0.517834 0.23386,-0.05011 0.116931,-0.65147 3.123712,-1.11919 1.637026,-0.183748 0.150339,0.701582 0.300678,-0.01669 0.233861,-0.684879 4.710626,-0.01669 0.05012,12.311104 0.334087,2.605876 0.734991,3.87541 -0.233863,0.668174 -8.485808,6.982415 0.200453,2.104747 9.755338,-3.541321 0.701582,2.605879 0.400905,0.06682 0.601356,-2.77292 9.888974,3.741772 0.06682,-2.271792 -8.418991,-7.082642 -0.200451,-0.601355 0.668174,-3.775184 0.367495,-2.973371 -0.03341,-11.860086 4.844259,-0.03341 0.167045,0.701583 h 0.23386 l 0.334087,-0.835218 1.5368,0.233862 3.107007,1.135893 0.133636,0.668176 0.23386,0.100225 0.06682,-0.634765 4.877668,1.703843 0.100227,0.668173 0.334086,-0.03341 0.133636,-0.601355 10.323285,4.176088 2.438834,1.703842 V 43.030412 L 62.77493,40.691805 42.462447,26.72697 l 0.367495,-0.534537 0.03341,-0.534539 0.400902,-0.06681 0.367496,-1.637026 -0.06681,-2.138156 -0.233863,-0.935443 -0.400904,-0.434313 -2.906557,-0.03341 -0.267269,0.634765 -0.100226,1.236122 0.03341,1.703843 0.200453,1.035669 L 35.279578,21.882712 35.312988,9.0871836 35.112536,7.6840188 33.97664,3.4745236 33.09655,1.3151182 Z",
            scale: 0.35,
            centerY: am5.p50,
            centerX: am5.p50,
            fill: am5.color("#383838"),
            strokeWidth: 1,
            stroke: am5.color("#c9c9c9"),
          })
        );

        return am5.Bullet.new(root, {
          sprite: plane,
        });
      });

      const destinations = this.destinations.map((el) => el.id);
      const originLongitude = this.center.longitude;
      const originLatitude = this.center.latitude;

      am5.array.each(destinations, (did) => {
        const destinationDataItem = this.arrivalsSeries.getDataItemById(did);
        if (destinationDataItem) {
          const lineDataItem = this.lineSeries.pushDataItem({
            geometry: {
              type: "LineString",
              coordinates: [
                [originLongitude, originLatitude],
                [
                  destinationDataItem.get("longitude"),
                  destinationDataItem.get("latitude"),
                ],
              ],
            },
          });

          // Calculate the approximate Euclidean distance
          const lat1 = originLatitude;
          const lon1 = originLongitude;
          const lat2 = destinationDataItem.get("latitude");
          const lon2 = destinationDataItem.get("longitude");
          const distance = Math.sqrt(
            Math.pow(lat2 - lat1, 2) + Math.pow(lon2 - lon1, 2)
          );

          // Only add midpoint if distance is greater than a certain threshold
          const distanceThreshold = 5; // You can change this value
          if (distance > distanceThreshold && this.planes_count < 10) {
            this.planes_count++;
            // Adding the animation
            const randomDelay = Math.random() * 20000; // Random delay between 0 and 8 seconds
            const timeout = setTimeout(() => {
              if (this.isComponentActive) {
                const planeDataItem = midPoints.pushDataItem({
                  lineDataItem: lineDataItem,
                  positionOnLine: 0,
                  autoRotate: true,
                  autoRotateAngle: 90,
                });

                const animation = planeDataItem.animate({
                  key: "positionOnLine",
                  from: 0,
                  to: 1,
                  duration: 20000,
                  loops: Infinity,
                  easing: am5.ease.inOut(am5.ease.sine),
                });
                this.animations.push(animation);
              }
            }, randomDelay);

            this.timeouts.push(timeout);
          }
        }
      });

      this.midPointsSeries = midPoints;
      this.destinations = [];
    },

    makeArrivals() {
      if (this.arrivalsSeries) {
        this.arrivalsSeries.dispose();
      }

      if (!this.root || !this.chart || !this.routes.length) return;

      const arrivals = this.chart.series.push(
        am5map.MapPointSeries.new(this.root, {})
      );

      arrivals.bullets.push(function (root, series, dataItem) {
        const bulletColor = am5.color(dataItem.get("color"));
        var container = am5.Container.new(root, {});
        container.children.push(
          am5.Circle.new(root, {
            radius: 7,
            fill: bulletColor,
            stroke: root.interfaceColors.get("alternativeBackground"),
            strokeWidth: 1,
            tooltipText: "{name}",
          })
        );

        return am5.Bullet.new(root, { sprite: container });
      });

      for (const [idx, route] of this.routes.entries()) {
        const [longitude, latitude] = route.geometry.coordinates[1];
        const item = {
          latitude: latitude,
          longitude: longitude,
          name: route.clientName,
          color: route.color,
          id: idx,
        };

        this.destinations.push(item);
        arrivals.pushDataItem(item);
      }

      this.arrivalsSeries = arrivals;
    },

    makeLines() {
      if (this.lineSeries) {
        this.lineSeries.dispose();
      }

      if (!this.root || !this.chart || !this.routes.length) return;
      const root = this.root;

      const lineSeries = this.chart.series.push(
        am5map.MapLineSeries.new(root, {
          lineType: "curved",
        })
      );

      lineSeries.mapLines.template.setAll({
        stroke: am5.color(0xffba00),
        strokeOpacity: 0.5,
        strokeDasharray: [10, 2],
        strokeWidth: 2,
      });

      lineSeries.data.setAll(this.routes);

      this.lineSeries = lineSeries;
    },

    colorCountries() {
      this.worldSeries.mapPolygons.each((x) => {
        const currIso = x.dataItem.dataContext.id;

        if (currIso === this.selectedCountry) {
          x.states.apply("selected");
          if (this.selected_country_risk_level) {
            x.states.apply(this.selected_country_risk_level);
          }
          return;
        }

        if (this.highlightedCountries.includes(currIso)) {
          x.states.apply("highlighted");
          return;
        }

        if (this.countriesSub.includes(currIso)) {
          x.states.apply("subscription");
          return;
        }

        x.states.apply("default");
      });
    },
    zoomToCountry(country) {
      const dataItem = this.worldSeries.getDataItemById(country);
      if (!dataItem) {
        return;
      }

      let geoPolygon = dataItem.dataContext.geometry.coordinates;

      if (!geoPolygon || !geoPolygon.length) {
        return;
      }

      let totalLatitude = 0;
      let totalLongitude = 0;
      let totalPoints = 0;

      const processCoordinates = (coordinates) => {
        if (typeof coordinates[0] === "number") {
          totalLatitude += coordinates[1];
          totalLongitude += coordinates[0];
          totalPoints++;
        } else {
          for (let coordinatePair of coordinates) {
            processCoordinates(coordinatePair);
          }
        }
      };

      processCoordinates(geoPolygon);

      let centerLatitude = totalLatitude / totalPoints;
      let centerLongitude = totalLongitude / totalPoints;

      if (isNaN(centerLatitude) || isNaN(centerLongitude)) {
        console.error("Invalid coordinates:", centerLatitude, centerLongitude);
        return;
      }

      // Zoom to the calculated geopoint with a specific zoom level
      if (this.chart) {
        this.chart.zoomToGeoPoint(
          { latitude: centerLatitude, longitude: centerLongitude },
          2,
          true,
          1000,
          0,
          0
        );
      }
    },
    async addNaturalDisastersAlerts() {
      if (this.eventsSeries) {
        this.eventsSeries.dispose();
      }

      // get events from api with getNaturalDisasters service
      const apiEvents = await NaturalDisastersService.getNaturalDisasters();
      if (!this.root || !this.chart || !apiEvents.length) return;
      const events = this.chart.series.push(
        am5map.MapPointSeries.new(this.root, {})
      );

      events.bullets.push(function (root, series, dataItem) {
        var container = am5.Container.new(root, {});
        const iconUrl = dataItem.get("icon");
        container.children.push(
          am5.Picture.new(root, {
            width: 24,
            height: 24,
            tooltipText: "{tooltipText}",
            src: iconUrl,
          })
        );

        return am5.Bullet.new(root, { sprite: container });
      });

      let eventItems = [];
      for (const event of apiEvents) {
        const item = {
          latitude: event.geometry.coordinates[1],
          longitude: event.geometry.coordinates[0],
          name: event.properties.name,
          color: "#FF0000",
          icon: event.properties.icon.replace(
            "https://www.gdacs.org/images/gdacs_icons/maps/",
            "/media/naturaldisasters/"
          ),
          tooltipText:
            `Description: ${event.properties.description}\n` +
            `Severity: ${event.properties.severitydata.severitytext}\n` +
            `Country: ${event.properties.country}\n` +
            `Date: ${event.properties.fromdate}`,
        };

        eventItems.push(item);
        events.pushDataItem(item);
      }

      this.events = eventItems;

      this.eventsSeries = events;
    },
    disposeNaturalDisastersAlerts() {
      if (this.eventsSeries) {
        this.eventsSeries.dispose();
      }
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 500px;
  background-color: #f9f9f9;
  border-radius: 5px;
}
</style>
