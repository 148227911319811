import api from "@/core/services/api.service"

export default {
  get PATH() {
    return "accuweather"
  },

  async getWeather(lat, lng, language = null) {
    const r = await api.get(`${this.PATH}/get_weather/`, {params: {lat, lng, language}})
    return r.data
  }
}