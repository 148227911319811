var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "label label-lg label-primary label-inline font-weight-bolder mr-2",
    style: "background-color: ".concat(_vm.color)
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }