import SecondmentService from "@/core/services/secondment/secondment.service";

const SERVICE_TYPE = {
  CLIENT: "client",
  INTRAGROUP: "intragroup",
  CONTRACT_WORK: "contract_work",
  NO_SERVICE_PROVISION: "no_service_provision",
};
Object.freeze(SERVICE_TYPE);

const CLIENT_TYPE = {
  COMPANY: "Company",
  ADMINISTRATIVE_STRUCTURE: "Company",
  PHYSICAL_PERSON: "PhysicalPerson",
};
Object.freeze(CLIENT_TYPE);

const STATUS_TYPE = {
  DRAFT: "draft",
  EDITED: "under_integration",
  CONFIRMED: "confirmed",
  CANCELLED: "cancelled",
  PROCESSING: "processing",
  COMPLETED: "completed",
};
Object.freeze(STATUS_TYPE);

async function updateSecondmentStatus(secondmentId, status, adminView) {
  return await SecondmentService.updateStatus({
    status,
  }, secondmentId, adminView);
}

export {
  SERVICE_TYPE,
  CLIENT_TYPE,
  STATUS_TYPE,
  updateSecondmentStatus
};