<template>
  <!--begin::Mixed Widget 14-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title font-weight-bolder ">{{ $t("Documents Expiring") }}</h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 text-center pt-5 font-size-h1 font-weight-bold">
        {{ documentExpiring }}
      </div>
      <div class="pt-5">
        <p class="text-center font-weight-normal font-size-lg pb-7">
          {{ $t("List documents expiring 60 days") }}
        </p>
        <b-button class="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"
          :to="{ name: 'employees-documents', params: { expiring: 'true' } }">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            {{ $t("Employees documents") }} </span>
        </b-button>
      </div>
    </div>
    <!--end::Body :to="{ name: 'employees-documents' }" -->
  </div>
  <!--end::Mixed Widget 14-->
</template>
<script>
import EmployeeService from "@/core/services/employee/employee.service";

export default {

  data() {
    return {
      documentExpiring: 0,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      EmployeeService.getDocuments({
        id: null,
        perPage: 1,
        page: 1,
        sortBy: "valid_to",
        search: null,
        sortDesc: null,
        expiring: true,
      })
        .then((res) => {
          this.documentExpiring = res.count;
        })
    },
  }
}
</script>