<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5 align-items-center">
      <h3 class="card-title">
        {{ $t("Employee distribution over time") }}
      </h3>
      <div class="card-toolbar m-0">
        <EuroSelect v-model="selected" class="gantt-select mb-n1" :options="selectComputed"></EuroSelect>
      </div>
    </div>
    <div class="card-body pt-0">
      <b-overlay :show="isLoading" rounded="sm">
        <template v-if="ganttItems.length > 0">
          <GanttChart :data="ganttItems" @columnAction="handleAction"></GanttChart>
        </template>
        <template v-else>
          <div class="d-flex align-items-center justify-content-between">
            <h3>
              <i class="flaticon2-graphic-1 text-primary svg-icon-lg" style="font-size: 2.4rem;"></i>
              <span> {{ $t("No data available at this time.") }} </span>
            </h3>
          </div>
          <span class="text-muted" style="font-size: 1.2rem;"> {{ $t("No data found for this graph.") }}</span>
        </template>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import GanttChart from "@/view/components/charts/GanttChart.vue";
import { generateRandomColor } from "@/core/helpers/colors";
export default {
  components: {
    GanttChart,
  },
  props: {
    ganttData: {
      type: [Object, Array],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: `${this.$t("All")}`,
      selOptions: [],
      show: true,
    };
  },
  computed: {
    selectComputed() {
      const employees = []
      this.ganttComputed
        .forEach(el => {
          let exist = employees.some(
            value => { return value.id == el.id });
          if (!exist) {
            employees.push(el)
          }
        })

      const employeeLabel = employees.map(el => ({ value: el.label, text: el.label }))
      const allEmployeeText = { value: `${this.$t("All")}`, text: `${this.$t("All")}` }
      return [allEmployeeText, ...employeeLabel];
    },
    ganttComputed() {
      const ganttKeys = Object.keys(this.ganttData);
      const computed = [];
      const emps = {};

      for (const employee of ganttKeys) {
        const emp = this.ganttData[employee];
        const empFullName = `${emp.name}-${emp.surname}`;
        if (emps[empFullName] == undefined) {
          emps[empFullName] = generateRandomColor();
        }
        const rgb = emps[empFullName];
        this.ganttData[employee].secondments.forEach((secondment) => {
          for (const el in secondment) {
            computed.push({
              id: this.ganttData[employee].id,
              label: `${this.ganttData[employee].name} ${this.ganttData[employee].surname}`,
              dateStart: secondment[el]["date_start"],
              dateEnd: secondment[el]["date_end"],
              redirect: { name: `detail-secondment`, params: { secondmentId: Object.keys(secondment)[0] } },
              color: `rgb(${rgb.r},${rgb.g},${rgb.b})`,
            });
          }
        });
      }
      return computed;
    },
    ganttItems() {
      if (this.selected && this.selected !== `${this.$t("All")}`)
        return this.ganttComputed.filter((el) => el.label === this.selected);
      return this.ganttComputed;
    },
  },

  watch: {
    ganttItems(val) {
      if (val.length) {
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
  },
  methods: {
    handleAction(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.gantt-select {
  min-width: 250px;
}
</style>
