var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "chartdiv",
    staticClass: "chart",
    style: "height: ".concat(_vm.height, "px")
  });

}
var staticRenderFns = []

export { render, staticRenderFns }