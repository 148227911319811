<template>
  <span class="label label-lg label-primary label-inline font-weight-bolder mr-2" :style="`background-color: ${color}`">
    {{ label }}
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
    label: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>