var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 py-5 align-items-center"
  }, [_c('h3', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Best clients")) + " ")])]), _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_vm.clientCount.length > 0 ? _c('div', {
    staticClass: "card-toolbar m-0"
  }, [_c('div', [_c('apexchart', {
    staticClass: "donut card-rounded-bottom mb-4",
    attrs: {
      "options": _vm.chartOptions,
      "series": _vm.series,
      "type": "donut"
    }
  })], 1)]) : _c('div', {
    staticClass: "p-4 text-center"
  }, [_c('h3', [_c('i', {
    staticClass: "flaticon2-graphic-1 text-primary svg-icon-lg",
    staticStyle: {
      "font-size": "2.4rem"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("No data")) + " ")]), _c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("You will need to create an ASSIGNMENT to view this graph.")))]), _c('div', {
    staticClass: "pt-5 mb-7"
  }, [_c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'create-secondment'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Tools/Compass.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create Assignment")) + " ")])])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }