<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-3">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5 align-items-center">
            <h3 class="card-title">
              {{ $t("Your report updates") }}
            </h3>
          </div>
          <div class="card-body pt-0">
            <div v-for="item in reports" :key="item.id" class="bg-light-secondary rounded p-5 mb-5 cursor-default">
              <div class="d-flex align-items-center updates-chip">
                <div class="mr-4">
                  <CountryFlag :country-iso="item.action_object_object_id">
                  </CountryFlag>
                </div>
                <div class="font-weight-bolder text-dark-75 font-size-xs text-capitalize">
                  <span v-if="item.target_representation != 'None'">
                    {{ item.target_representation }}
                  </span>
                  <span v-else>
                    {{ item.actor_representation }}
                  </span>
                  {{ $t("Update") }}
                  <span v-b-tooltip.hover class="updates-chip-date"
                    :title="DateService.format(item.timestamp, dateFormat + ' HH:mm:ss')">{{
                      DateService.timeAgo(item.timestamp) }}</span>
                </div>
              </div>
            </div>
            <div v-if="reports.length == 0">
              <span class="text-muted" style="font-size: 1.2rem;"> {{ $t("Cannot find reports updates.") }}</span>
              <div class="mt-7">
                <router-link :to="{ name: 'reports' }" class="btn btn-success font-weight-bolder text-uppercase w-60">
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
                  </span>
                  {{ $t("Subscribe here") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-lg-3">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5 align-items-center">
            <h3 class="card-title">
              {{ $t("Your map updates") }}
            </h3>
          </div>
          <div class="card-body pt-0">
            <div v-for="item in maps" :key="item.id" class="bg-light-secondary rounded p-5 mb-5 cursor-default">
              <div class="d-flex align-items-center updates-chip">
                <div class="mr-4">
                  <CountryFlag :country-iso="item.target_object_id">
                  </CountryFlag>
                </div>
                <div class="font-weight-bolder text-dark-75 font-size-xs text-capitalize">
                  <span>
                    {{ countriesMap[item.target_object_id]?.name }}
                    {{ item.verb.split(" ")[0] }}
                  </span>
                  <span v-b-tooltip.hover class="updates-chip-date"
                    :title="DateService.format(item.timestamp, dateFormat + ' HH:mm:ss')">{{
                      DateService.timeAgo(item.timestamp) }}</span>
                </div>
              </div>
            </div>
            <div v-if="maps.length == 0">
              <span class="text-muted" style="font-size: 1.2rem;"> {{ $t("Cannot find map updates.") }}</span>
              <div class="mt-7">
                <router-link :to="{ name: 'reports' }" class="btn btn-success font-weight-bolder text-uppercase w-60">
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
                  </span>
                  {{ $t("Subscribe here") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5 align-items-center">
            <h3 class="card-title">
              {{ $t("Your assignments updates") }}
            </h3>
          </div>
          <div class="card-body pt-0">
            <div v-for="item in secondments" :key="item.id" class="bg-light-secondary rounded p-5 mb-5 cursor-default">
              <div class="d-flex align-items-center updates-chip">
                <div class="font-weight-bolder text-dark-75 font-size-xs text-capitalize mr-2">
                  <span>
                    {{ item.target_representation }}
                  </span>
                </div>
                <div class="font-weight-normal font-size-xs text-capitalize">
                  <span>
                    {{ item.verb }}
                  </span>
                </div>
                <span v-b-tooltip.hover class="updates-chip-date"
                  :title="DateService.format(item.timestamp, dateFormat + ' HH:mm:ss')">{{
                    DateService.timeAgo(item.timestamp) }}</span>
              </div>
            </div>
            <div v-if="secondments.length == 0">
              <span class="text-muted" style="font-size: 1.2rem;">
                {{ $t("Cannot find assignments updates. Make sure you have at least one assignment.") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationService from "@/core/services/notification/notification.service";
import DateService from "@/core/services/date.service";
import { mapGetters, mapState } from "vuex";
import icons from "@/core/config/icons";
import CountryFlag from "@/view/components/CountryFlag.vue";

export default {
  components: {
    CountryFlag
  },

  data() {
    return {
      total: 0,
      loading: false,
      items_list: [],
      DateService,
      tableOptions: {
        "sort-by": "timestamp",
        "sort-desc": true,
        "per-page": 5,
        "current-page": 1,
      },
      fields: [
        { key: "state", label: "NATION" },
        { key: "verb", label: "MESSAGE" },
      ],
      secondmentsFields: [
        { key: "client", label: "CLIENT" },
        { key: "verb", label: "MESSAGE" },
      ],
      icons,
      maps: [],
      reports: [],
      secondments: [],
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),
    ...mapState("constants", ["notificationLevelsMap"]),
    ...mapGetters("constants", ["countriesMap"]),

    tablePropsReport() {
      return {
        busy: this.loading,
        fields: this.fields,
        filter: this.search,
      };
    },
    tablePropsMap() {
      return {
        busy: this.loading,
        fields: this.fields,
        filter: this.search,
      };
    },
    tablePropsSecondment() {
      return {
        busy: this.loading,
        fields: this.secondmentsFields,
        filter: this.search,
      };
    },
  },
  mounted() {
    NotificationService.getAll({
      perPage: 25,
      page: 1,
      sortBy: "",
      sortDesc: "",
      search: [],
      omit: [],
      dashboard: true
    }).then(res => {
      this.items_list = res.results.filter(el => el.data.data != null).map(el => el.data);
      this.reports = this.items_list.filter(el => el.data.kind == "report_update")
      this.maps = this.items_list.filter(el => el.data.kind == "risk_map_update")
      this.secondments = this.items_list.filter(el => el.data.notification_type == "risk_map_secondment_update")
    })
  },
};
</script>

<style lang="scss" scoped>
.read-icon {
  font-size: 1.5rem;
}

.updates-chip {
  position: relative;
}

.updates-chip-date {
  position: absolute;
  bottom: -0.9rem;
  right: 0.1rem;
  font-size: 0.8em;
  color: #888;
}
</style>
