<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-bind="bCardBindings" :body-class="bodyClass" class="width h-100">
    <b-overlay rounded="sm" :show="isLoading">
      <div v-if="countryIso && !isLoading">
        <div v-if="countryMaxRiskLevel && countryMaxRiskLevel.max_risk > 0">
          <risk-map-date-alert :date="currentDay" :country-name="countriesMap[countryIso]?.name"></risk-map-date-alert>
          <div class="separator separator-dashed my-5"></div>
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-capitalize mr-3">{{ $t("Max risk level procedure") }}:</div>
            <rectangle :label="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.label"
              :color="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.color"></rectangle>
          </div>
          <div class="separator separator-dashed my-5"></div>
          <div>
            <span class="d-inline-block font-weight-bold text-capitalize mb-1">{{ $t("Risk from other Countries")
              }}:</span>
            <div>
              <rectangle v-for="(risk, idx) in countryMaxRiskLevel.max_risk_data" :key="idx" v-b-tooltip.hover
                :title="riskLevelLegend[risk.risk - 1]?.label" :label="countriesMap[risk.country]?.name"
                :color="riskLevelLegend[risk.risk - 1]?.color" class="m-1 rectangle-item"></rectangle>
            </div>
          </div>
          <div class="separator separator-dashed my-5"></div>
        </div>
        <div v-for="(statistic, idx) in statistics" :key="idx"
          class="d-flex align-items-center justify-content-between pb-2">
          <div class="font-weight-bold text-capitalize">
            {{ statistic.label }}:
          </div>
          <div class="text-right font-weight-bold">
            <span v-if="typeof statistic.value == 'number'" class="font-weight-bolder"
              :class="[statistic.value > 0 ? 'text-success' : 'text-danger']">
              {{ statistic.value > 0 ? `+${statistic.value}` : statistic.value }}
            </span>
            <span v-else>
              {{ statistic.value }}
            </span>
          </div>
        </div>
        <div class="separator separator-dashed mt-2 mb-5"></div>
        <div v-if="riskMapAvailability">
          <button class="btn btn-block btn-light-primary font-weight-bolder text-uppercase" @click="openTravelAdvice">
            <i :class="icons.map"></i>
            {{ $t("Risk Map Advice") }}
          </button>
        </div>
        <div v-else>
          <button class="btn btn-block btn-success font-weight-bolder text-uppercase" @click="addRiskMapInCart()">
            <i class="fas fa-cart-arrow-down"></i>
            {{ $t("Buy Risk Map") }}
          </button>
        </div>
        <div v-if="!isOnlyMaps">
          <div v-if="reportAvailability">
            <router-link class="btn btn-block btn-light-primary font-weight-bolder text-uppercase mt-3" :to="{
    name: 'reports',
    params: countryIso,
    query: { iso: countryIso }
  }">
              <i :class="icons.document"></i>
              {{ $t("SHOW REPORT") }}
            </router-link>
          </div>
          <div v-else>
            <router-link class="btn btn-block btn-success font-weight-bolder text-uppercase mt-3" :to="{
    name: 'reports',
    params: countryIso,
    query: { iso: countryIso }
  }">
              <i class="fas fa-cart-arrow-down"></i>
              {{ $t("Buy Reports") }}
            </router-link>
          </div>
        </div>
      </div>
    </b-overlay>
    <div v-if="!countryIso && showEmptyMessage" class="font-weight-bold h4 text-center px-6 py-8">
      {{ $t("Select a country for more information") }}
    </div>

    <b-modal v-if="riskMapAvailability" :id="modalId" size="xl" hide-footer @hidden="closeTravelAdvice">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-start">
          <div>
            <h3 class="mb-3 mt-1 font-weight-bolder text-uppercase">
              {{ $t("Risk Map Advice") }} {{ countriesMap[countryIso].name }}
            </h3>
            <div v-for="(statistic, idx) in statistics" :key="idx"
              class="d-flex align-items-center justify-content-left pb-2">
              <i :class="[statistic.icon, statistic.iconClass]" class="mr-3"></i>
              <div class="font-weight-bolder text-capitalize">{{ statistic.label }}:</div>
              <div class="text-right font-weight-bold ml-3" :class="statistic.class">
                <span v-if="typeof statistic.value == 'number'" class="font-weight-bolder"
                  :class="[statistic.value > 0 ? 'text-success' : 'text-danger']">
                  {{ statistic.value > 0 ? `+${statistic.value}` : statistic.value }}
                </span>
                <span v-else>
                  {{ statistic.value }}
                </span>
              </div>
            </div>
            <div v-if="countryMaxRiskLevel && countryMaxRiskLevel.max_risk > 0" class="mb-0">
              <div class="d-flex align-items-center">
                <span class="font-weight-bolder text-capitalize mr-2">
                  {{ $t("General Max Risk Level Procedure of Country") }}:
                </span>
                <rectangle v-b-tooltip.hover :title="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]"
                  :label="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.label"
                  :color="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.color"></rectangle>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <router-link v-if="!isOnlyMaps" class="btn btn-block btn-success font-weight-bolder text-uppercase mr-3"
              :to="{
    name: 'reports',
    params: countryIso,
    query: { iso: countryIso }
  }">
              <i class="fas fa-cart-arrow-down"></i>
              {{ $t("Report Request") }}
            </router-link>
            <b-button @click="closeTravelAdvice">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>
      <b-row>
        <b-col cols="12" lg="12">
          <p class="font-weight-bold overlay-label">{{ $t("Map data overlay") }}</p>
          <ul class="button-list">
            <li class="mt-0">
              <button class="btn btn-secondary font-weight-bolder text-uppercase mt-3 btn-sm btn-overlay"
                @click="toggleRiskOverlay">
                <i :class="riskOverlay ? icons.check_circle : icons.circle"> </i>
                {{ $t("Risk map") }}
              </button>
            </li>
            <li class="mt-0">
              <button class="btn btn-secondary font-weight-bolder text-uppercase mt-3 btn-sm btn-overlay"
                @click="showNaturalDisasters">
                <i :class="naturalDisastersOverlay ? icons.check_circle : icons.circle"> </i>
                {{ $t("Natural disasters") }}
              </button>
            </li>
          </ul>
          <google-maps-map height="400px" class="border" :geocoder-options="gmapGeocoder" :kml-layers-urls="kmlUrl"
            :markers="markers" :directions="directions" :map-options="map_options" :geo-json-layers="geoJsonLayers"
            @kml-click="onKmlClick"></google-maps-map>
          <ul class="level-legend mt-4">
            <li v-for="(levelLegend, idx) in riskLevelLegend" :key="idx" class="d-flex align-items-center pl-3">
              <rectangle :label="levelLegend.label" :color="levelLegend.color"></rectangle><span
                class="mr-2 font-weight-normal">{{ levelLegend.text }}</span>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" lg="12">
          <div class="mb-1">
            <i :class="icons.map" class="pr-1"></i>
            {{ $t("Pointer location") }}:
          </div>
          <google-maps-autocomplete v-model="autocompleteStartLocation" :autocomplete-options="autocompleteOptions">
            <template #append>
              <b-btn variant="primary" :disabled="!canAddDestinations" @click="addDestination">
                <i :class="icons.add"></i>
              </b-btn>
            </template>
            <template #pointer-location>
              <div v-if="autocompleteStartLocation == null">
                <div class="text-muted small">{{ $t("Enter location or click on the map to see more information")
                  }}</div>
              </div>
            </template>
          </google-maps-autocomplete>
          <google-maps-autocomplete v-for="(destination, idx) in destinations" :key="destination.key"
            v-model="destination.place" :label="idx == destinations.length - 1 ? 'Destination' : 'Waypoint'"
            :autocomplete-options="autocompleteOptions">
            <template #append>
              <b-btn variant="danger" @click="removeDestination(destination.key)">
                <i :class="icons.delete"></i>
              </b-btn>
            </template>
          </google-maps-autocomplete>
        </b-col>
        <b-col cols="12" lg="6">
          <div v-if="startLocationRisk">
            <div class="d-flex align-items-center mb-4">
              <div class="font-weight-bold mr-2">
                {{ $t("Selected location max risk level") }}:
              </div>
              <b-overlay rounded="sm" :show="startLocationRiskLoading" :opacity="0">
                <div v-if="startLocationRisk" class="d-flex justify-content-end font-weight-bold">
                  <rectangle v-b-tooltip.hover :label="riskLevelLegend[startLocationMaxRiskStatus - 1]?.label"
                    :title="riskLevelLegend[startLocationMaxRiskStatus - 1]"
                    :color="riskLevelLegend[startLocationMaxRiskStatus - 1]?.color"></rectangle>
                </div>
              </b-overlay>
            </div>
            <div v-if="startLocationRisk && startLocationMaxRiskCountries.length" class="mt-5">
              <rectangle v-for="country in startLocationMaxRiskCountries" :key="country.countryIso" v-b-tooltip.hover
                class="mb-2" :color="riskLevelLegend[country.value - 1]?.color"
                :label="countriesMap[country.countryIso].name">
              </rectangle>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div v-if="startLocationWeather">
            <div>{{ $t("Selected location weather") }}:</div>
            <div>
              <b-overlay rounded="sm" :show="startLocationWeatherLoading" :opacity="0">
                <div v-if="startLocationWeather" class="font-weight-bold">
                  <img :src="`/media/weather/icons/${startLocationWeather?.WeatherIcon}.svg`" />
                  {{ startLocationWeather?.WeatherText }}
                  {{ startLocationWeather?.Temperature?.Metric?.Value }}°{{
    startLocationWeather?.Temperature?.Metric?.Unit
  }}
                </div>
              </b-overlay>
            </div>
          </div>

          <div v-if="airQuality" class="separator separator-dashed my-0"></div>
          <div v-if="airQuality">
            <div class="d-flex align-items-center mt-2">
              <div>
                <i class="fas fa-smog"></i>
                <span class="ml-2">{{ $t("Pollution Index") }}:</span>
              </div>
              <rectangle v-b-tooltip.hover class="ml-4" :title="airQuality.pollution_description"
                :color="airQualityColor" :label="airQuality.aqi + ''"></rectangle>
            </div>
            <div class="d-flex align-items-center mt-2">
              <div>
                <i class="fas fa-road"></i>
                <span class="ml-2">{{ $t("Distance from station") }}:</span>
              </div>
              <div class="ml-2">{{ airQuality.distance_from_station }} Km</div>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="pre-assessment">
            <h2>{{ $t("Country Assessment") }}</h2>
            <div v-if="showPerAssessment" class="pre-content">
              <span v-html="convertMarkdownToHtml(contentPerAssessment)"></span>
              <h2>{{ $t("Vaccinations & Risk Categories") }}</h2>
              <div class="row">
                <div v-if="mandatoryVaccinations.length || recommendedVaccinations.length" class="col">
                  <div v-if="mandatoryVaccinations.length" class="mb-6">
                    <h3>{{ $t("Mandatory Vaccinations:") }}</h3>
                    <div v-for="mandatoryVaccination in mandatoryVaccinations" :key="mandatoryVaccination">
                      <div class="d-flex align-items-center">
                        <span class="mb-2 badge badge-warning">{{ mandatoryVaccination.vaccine_name }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="recommendedVaccinations.length">
                    <h3>{{ recommendedVaccinations.length }} {{ $t("Recommended Vaccinations:") }}</h3>
                    <div v-for="recommendedVaccination in recommendedVaccinations" :key="recommendedVaccination">
                      <div class="d-flex align-items-center">
                        <span class="mb-2 badge badge-info">{{ recommendedVaccination.vaccine_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="categoriesOfRisks.length" class="col">
                  <h3>{{ $t("Risk Categories:") }}</h3>
                  <div v-for="categorieRisk in categoriesOfRisks" :key="categorieRisk.id">
                    <div class="d-flex align-items-center">
                      <span class="mb-2 badge badge-secondary">{{ categorieRisk.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="pr-4">{{ $t("Request pre-assessment of the country") }}</span>
              <button class="btn btn-success font-weight-bolder text-uppercase" @click="requestPerAssessment">
                <span class="svg-icon svg-icon-md">
                  <inline-svg src="/media/svg/icons/General/Binocular.svg" />
                </span>
                {{ $t("Request Pre Assessment") }}
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import GoogleMapsMap from "@/view/components/google-maps/GoogleMapsMap.vue";
import GoogleMapsAutocomplete from "@/view/components/google-maps/GoogleMapsAutocomplete.vue";
import RiskService from "@/core/services/risk/risk.service";
import icons from "@/core/config/icons";
import NationCurrenciesService from "@/core/services/nation/nation-currencies.service";
import Rectangle from "@/view/components/shapes/Rectangle.vue";
import AccuWeatherService from "@/core/services/accuweather.service";
import DateService from "@/core/services/date.service";
import RiskMapDateAlert from "@/view/components/alerts/RiskMapDateAlert.vue";
import Swal from "sweetalert2";
import { backendErrorSwal } from "@/core/helpers/swal";
import NaturalDisastersService from "@/core/services/natural-disasters.service";
import eventBus from "@/core/services/event/eventBus"
import axios from "axios";
import MarkdownIt from "markdown-it";
import i18n from "@/core/plugins/vue-i18n";


export default {
  components: {
    GoogleMapsMap,
    GoogleMapsAutocomplete,
    Rectangle,
    RiskMapDateAlert
  },

  props: {
    countryIso: {
      type: String,
      default: "",
    },
    bCardBindings: {
      type: Object,
      default: null,
    },
    showEmptyMessage: {
      type: Boolean,
      default: true
    },
    compact: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      isLoading: false,
      numbeoStatistics: null,
      exchangeRate: null,
      airQuality: null,
      freedomIndex: null,
      kmlUrl: [],
      geoJsonLayers: [],
      naturalDisastersOverlay: false,
      riskOverlay: true,
      riskLevelLegend: [
        { code: 1, label: "A", text: this.$t("Low"), color: "#16a085" },
        { code: 2, label: "B", text: this.$t("Moderate"), color: "#f1c40f" },
        { code: 3, label: "C", text: this.$t("Medium"), color: "#f39c12" },
        { code: 4, label: "D", text: this.$t("High"), color: "#c0392b" },
      ],
      icons,
      autocompleteStartLocation: null,
      destinations: [],
      MAX_DESTINATIONS: 2,
      travelAdviceStats: [],
      startLocationRisk: null,
      startLocationRiskLoading: false,
      countryMaxRiskLevel: null,
      countryRiskLevel: null,
      startLocationWeather: null,
      startLocationWeatherLoading: null,
      postCategories: [],
      countriesToHighlight: [],
      map_options: { min_zoom: 1 },
      showPerAssessment: false,
      contentPerAssessment: '',
      mandatoryVaccinations: '',
      recommendedVaccinations: '',
      categoriesOfRisks: '',
      md: new MarkdownIt(),
    };
  },

  computed: {
    ...mapGetters("user", ["getUserExpandedMainAddressCountryIso", "dateFormat", "isOnlyMaps"]),
    ...mapGetters("constants", ["countriesMap"]),
    ...mapState("constants", ["countries"]),
    ...mapState("capabilities", ["capabilities", "risk_map"]),

    riskMapAvailability() {
      let check = false
      this.capabilities.forEach(element => {
        if (element.country == this.countryIso && element.risk_map) {
          check = true
        }
      });
      return check
    },

    reportAvailability() {
      let check = false
      this.capabilities.forEach(element => {
        // A valid condition to check reports is if the categories contain any report other than risk map
        const hasReports = element.categories.filter(({ name }) => name.toLowerCase() !== 'riskmaps').length > 0
        if ((element.country == this.countryIso) && hasReports) {
          check = true
        }
      });
      return check
    },

    currentDay() {
      return DateService.format(new Date(), this.dateFormat);
    },

    homeCountryIso() {
      return this.getUserExpandedMainAddressCountryIso;
    },

    airQualityColor() {
      const index = parseInt(this.airQuality?.aqi);
      let color = "grey";
      if (index < 50) color = "green";
      if (index > 50 && index <= 100) color = "gold";
      if (index > 100 && index <= 150) color = "orange";
      if (index > 150 && index <= 200) color = "red";
      if (index > 200 && index <= 200) color = "purple";
      if (index > 300) color = "grey";
      return color;
    },

    bodyClass() {
      const out = {
        "d-flex align-items-center": !this.countryIso,
        "p-0": true,
      };
      return out;
    },
    statistics() {
      const out = [{ label: "Currency", value: this.countriesMap[this.countryIso]?.currency_name, icon: icons.bill }];

      if (this.exchangeRate) {
        out.push({
          label: "Exchange Rate",
          value: `1 ${this.countriesMap[this.homeCountryIso]?.currency_code} = ${this.exchangeRate.repr}`,
          icon: icons.exchange,
        });
      }

      let freedom_index = this.countriesMap[this.countryIso]?.freedom_index;
      if (freedom_index) {
        out.push({
          label: "Freedom Index",
          value: freedom_index.personal_freedom,
          icon: freedom_index.personal_freedom < 6.82 ? 'fas fa-exclamation-triangle' : 'fas fa-shield-alt',
          class: freedom_index.personal_freedom >= 6.82 ? 'text-success' : 'text-danger',
          iconClass: freedom_index.personal_freedom < 6.82 ? 'text-danger' : '',
        });
      }

      if (this.numbeoStatistics) {
        for (const key of Object.keys(this.numbeoStatistics)) {
          out.push({
            label: key.replaceAll("_", " "),
            value: Math.round(this.numbeoStatistics[key].value * 100) / 100,
            icon: this.numbeoStatistics[key].icon,
          });
        }
      }

      return out;
    },
    gmapGeocoder() {
      return {
        componentRestrictions: {
          country: this.countryIso,
        },
      };
    },

    autocompleteOptions() {
      return {
        componentRestrictions: { country: this.countryIso },
      };
    },

    modalId() {
      return `modal-${this._uid}`;
    },

    markers() {
      let m = [];

      if (this.autocompleteStartLocation) {
        m = [
          {
            position: {
              lat: this.autocompleteStartLocation?.geometry?.location?.lat(),
              lng: this.autocompleteStartLocation?.geometry?.location?.lng(),
            },
            name: this.autocompleteStartLocation?.formatted_address,
          },
        ];
      }

      this.destinations.forEach((destination) => {
        if (!destination.place) return;

        m.push({
          position: {
            lat: destination?.place?.geometry?.location?.lat(),
            lng: destination?.place?.geometry?.location?.lng(),
          },
          name: destination?.place?.formatted_address,
        });
      });

      return m;
    },

    destinationsPlaces() {
      return this.destinations.map((d) => d.place);
    },

    directions() {
      const out = {};
      const places = [this.autocompleteStartLocation, ...this.destinationsPlaces];

      if (places.length < 2) return out;
      const lastPlaceIdx = places.length - 1;
      places.forEach((place, idx) => {
        if (!place) return;
        out.travelMode = google.maps.TravelMode.DRIVING;
        switch (idx) {
          case 0:
            out.origin = place.geometry.location;
            break;
          case lastPlaceIdx:
            out.destination = place.geometry.location;
            break;
          default:
            if (!out.waypoints) out.waypoints = [];
            out.waypoints.push({ location: place.geometry.location });
            break;
        }
      });

      return out;
    },

    canAddDestinations() {
      return this.MAX_DESTINATIONS > this.destinations.length;
    },

    startLocationMaxRiskStatus() {
      return this.startLocationRisk?.risk ?? 0;
    },

    startLocationMaxRiskCountries() {
      if (!this.startLocationRisk?.risk_status) {
        return [];
      }

      return Object.keys(this.startLocationRisk?.risk_status).reduce((acc, key) => {
        const value = this.startLocationRisk?.risk_status[key];
        acc.push({ countryIso: key, value: value });
        return acc;
      }, []);
    },
  },

  watch: {
    countryIso() {
      this.getStatistics();
      this.getKmlLayerUrl();
    },

    async autocompleteStartLocation(startLocation) {
      if (startLocation) {
        this.loadStartLocationRisk(startLocation);
        this.loadStartLocationWeather(startLocation);
      }
    },
  },

  methods: {
    ...mapActions("user", ["setUserCompanyAddress"]),
    ...mapActions("cart", ["addItem", "addParentItem", "removeItem"]),

    async getStatistics() {
      if (!this.countryIso) return;
      await this.getPerAssessment()

      if (!this.homeCountryIso) {
        try {
          await this.setUserCompanyAddress();
          if (!this.homeCountryIso) {
            Swal.fire({
              icon: "warning",
              title: "Please upload an address of your company to get comparison data",
            });
            return;
          }
        } catch (err) {
          backendErrorSwal(err?.response?.detail, err);
        }
      }

      this.isLoading = true;
      try {
        this.exchangeRate = await NationCurrenciesService.exchangeRate(
          this.countriesMap[this.homeCountryIso]?.currency_code,
          this.countriesMap[this.countryIso]?.currency_code,
        );
      }
      catch (err) {
        this.exchangeRate = null;
      }
      try {
        const [countryMaxRiskLevel] = await Promise.all([
          RiskService.getCountryMaxRiskLevel(this.countryIso),
        ]);

        if (countryMaxRiskLevel.max_risk === 4 || countryMaxRiskLevel.max_risk === 3) this.$emit("max-risk-alert");

        this.countryMaxRiskLevel = countryMaxRiskLevel
        this.$emit('update-selected-color', countryMaxRiskLevel.max_risk);
      } catch (err) {
        this.countryMaxRiskLevel = null;
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async getKmlLayerUrl() {
      if (!this.risk_map.includes(this.countryIso)) {
        return
      }
      try {
        if (this.riskOverlay) {
          const { kml_url: url } = await RiskService.getCountryRiskMap(this.countryIso);
          this.kmlUrl = [url];
        }
        else {
          this.kmlUrl = [];
        }
      } catch (err) {
        this.kmlUrl = [];
        console.log('====================================');
        console.log('@getKmlLayerUrl card', err);
        console.log('====================================');
      }
    },

    async openTravelAdvice() {
      await this.getKmlLayerUrl();
      this.$bvModal.show(this.modalId);
    },

    closeTravelAdvice() {
      this.$bvModal.hide(this.modalId);
      this.kmlUrl = [];
      this.autocompleteStartLocation = null;
      this.destinations = [];
      this.startLocationRisk = null;
      this.naturalDisastersOverlay = false;
      this.riskOverlay = true;
    },

    onKmlClick(payload) {
      this.autocompleteStartLocation = payload;
    },

    addDestination() {
      if (this.canAddDestination) {
        return;
      }
      const key = (Math.random() + 1).toString(36).substring(7);
      this.destinations.unshift({ key, place: null });
    },

    removeDestination(key) {
      const idx = this.destinations.findIndex((destination) => destination.key === key);
      if (idx == -1) return;
      this.destinations.splice(idx, 1);
    },

    async loadStartLocationRisk(startLocation) {
      this.startLocationRiskLoading = true;
      this.startLocationRisk = null;

      try {
        this.startLocationRisk = await RiskService.getPlaceRiskLevel(
          this.countryIso,
          startLocation.geometry.location.lat(),
          startLocation.geometry.location.lng(),
        );
        this.airQuality = await RiskService.getAirQualityData({
          lat: startLocation.geometry.location.lat(),
          lng: startLocation.geometry.location.lng(),
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.startLocationRiskLoading = false;
      }
    },
    async loadStartLocationWeather(startLocation) {
      this.startLocationWeatherLoading = true;
      this.startLocationWeather = null;
      try {
        const weather = await AccuWeatherService.getWeather(
          startLocation.geometry.location.lat(),
          startLocation.geometry.location.lng(),
          localStorage.getItem("language") || "en",
        );
        this.startLocationWeather = weather[0];
      } catch (err) {
        console.log('@loadStartLocationWeather', err);
      } finally {
        this.startLocationWeatherLoading = false;
      }
    },
    async showNaturalDisasters() {
      if (!this.naturalDisastersOverlay) {
        this.naturalDisastersOverlay = true;
        try {
          const CountryNaturalDisasters = await NaturalDisastersService.getNaturalDisasters(this.countryIso);
          // get urls of geoJSON files of all natural disasters for this country
          if (CountryNaturalDisasters.length > 0) {
            const geoJSONUrls = CountryNaturalDisasters.map((disaster) => disaster.properties.url);
            // get the geoJSON from geoJSONUrls .geometry url
            const geoJSONs = await Promise.all(geoJSONUrls.map((url) => axios.get(url.geometry)));
            this.geoJsonLayers = geoJSONs

          }
        } catch (err) {
          console.log('@showNaturalDisasters', err);
        }
      } else {
        this.naturalDisastersOverlay = false;
        this.geoJsonLayers = []
      }
    },
    toggleRiskOverlay() {
      this.riskOverlay = !this.riskOverlay;
      this.getKmlLayerUrl();
    },
    addRiskMapInCart() {
      const item = this.countries.find(el => el.iso == this.countryIso)
      const newItem = {
        name: 'Risk Map',
        slug: 'risk-map',
        type: 'map',
        price: item.risk_maps.user_price.monthly, // User_price ha tutti i prezzi su duration
        countries: [item.iso],
        id: item.risk_maps.id // Questo è l'id del listino prezzi delle mappe
      }
      this.addItem(newItem)
      eventBus.$emit('open-mini-cart')
    },
    convertMarkdownToHtml(markdownText) {
      return this.md.render(markdownText);
    },
    async getPerAssessment() {
      this.contentPerAssessment = ''
      this.mandatoryVaccinations = ''
      this.recommendedVaccinations = ''
      this.categoriesOfRisks = ''

      this.showPerAssessment = false

      await RiskService.getCountryPerAssessment(this.countryIso).then((r) => {
        if (r.status == 'ok' && r.data.assessment_json && r.data.assessment_json.hasOwnProperty('pre_assessment')) {
          this.contentPerAssessment = r.data.assessment_json.pre_assessment
          this.recommendedVaccinations = r.data.assessment_json.recommended_vaccinations
          this.mandatoryVaccinations = r.data.assessment_json.mandatory_vaccinations
          this.categoriesOfRisks = r.data.assessment_json.categories_of_risks
          this.showPerAssessment = true
        }

      }).catch((err) => {
        console.log(err)
        backendErrorSwal(null, i18n('There was an error, contact technical support'));
      });
    },
    async requestPerAssessment() {
      await RiskService.requestCountryPerAssessment(this.countryIso).then(() => {
        Swal.fire({
          icon: "info",
          title: this.$t("Your request has been sent to the risk assessment team, you will receive a notification as soon as it is ready"),
        });
      }).catch(() => {
        backendErrorSwal(null, i18n.t('There was an error, contact technical support'));
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.width {
  min-width: 150px;
}

ul {
  list-style: none;

  &.level-legend {
    display: flex;
    gap: 1px;
    justify-content: right;
  }
}

.rectangle-item {
  flex: 1 0 auto;
}

.two-per-line .rectangle-item {
  flex-basis: calc(50% - 10px);
  /* 50% width, accounting for 5px margin on each side */
}

.button-list {
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin-bottom: 2px;
}

.btn-overlay {
  margin-top: 0 !important;
}

.overlay-label {
  margin-bottom: 0 !important;
}

.pre-assessment h2 {
  border-bottom: 1px solid #EBEDF3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  margin-top: 2rem;
}
</style>
<style>
.pre-content ul {
  padding-left: 2rem;
}
</style>
