<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5 align-items-center">
      <h3 class="card-title">
        {{ $t("Best clients") }}
      </h3>
    </div>
    <b-overlay :show="isLoading" rounded="sm">
      <div v-if="clientCount.length > 0" class="card-toolbar m-0">
        <div>
          <apexchart class="donut card-rounded-bottom mb-4" :options="chartOptions" :series="series" type="donut">
          </apexchart>
        </div>
      </div>
      <div v-else class="p-4 text-center">
        <h3>
          <i class="flaticon2-graphic-1 text-primary svg-icon-lg" style="font-size: 2.4rem;"></i>
          {{ $t("No data") }}
        </h3>
        <span class="text-muted" style="font-size: 1.2rem;">
          {{ $t("You will need to create an ASSIGNMENT to view this graph.") }}</span>
        <div class="pt-5 mb-7">
          <router-link :to="{ name: 'create-secondment' }" class="btn btn-light-primary font-weight-bolder">
            <div class="d-flex">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
              </span>
              {{ $t("Create Assignment") }}
            </div>
          </router-link>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  props: {
    clientCount: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOptions: {},
      series: [],
      labels: []
    };
  },
  computed: {
    total() {
      return this.clientCount.reduce((total, el) => total + el.secondments, 0);
    },
  },
  watch: {
    clientCount(val) {
      val.forEach(element => {
        this.series.push(element.secondments)
        this.labels.push(element.client)
      })
    }
  },
  mounted() {
    // Create root and chart
    this.chartOptions = {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        horizontalAlign: 'center',
        position: 'bottom'
      },
      labels: this.labels
    };

    this.clientCount.forEach(element => {
      this.series.push(element.secondments)
      this.labels.push(element.client)
    })
  }
};
</script>