var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('secondments-map-widget')], 1), _c('div', {
    staticClass: "col-12 col-lg-9"
  }, [_c('next-secondments-widget', {
    attrs: {
      "next-secondments": _vm.nextSecondments,
      "is-loading": _vm.isLoading
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-3"
  }, [_c('risk-level-widget')], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('notification-updates-report')], 1), _c('div', {
    staticClass: "col-12 col-lg-9"
  }, [_c('gantt-widget', {
    attrs: {
      "gantt-data": _vm.employeeGantt,
      "is-loading": _vm.isLoading
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-3"
  }, [_c('expairing-widget')], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('nations-secondments-days', {
    attrs: {
      "filter-value": _vm.nationSecondmentDaysFilter,
      "nations-secondments": _vm.filteredNationsSecondmentsByCountry,
      "is-loading": _vm.isLoading
    },
    on: {
      "update:filterValue": function updateFilterValue($event) {
        _vm.nationSecondmentDaysFilter = $event;
      },
      "update:filter-value": function updateFilterValue($event) {
        _vm.nationSecondmentDaysFilter = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('secondments-days-for-declaration', {
    attrs: {
      "filter-value": _vm.nationSecondmentDaysFilter,
      "employees-days-for-declaration-secondments": _vm.employeesDaysForDeclaration,
      "is-loading": _vm.isLoading
    },
    on: {
      "update:filterValue": function updateFilterValue($event) {
        _vm.nationSecondmentDaysFilter = $event;
      },
      "update:filter-value": function updateFilterValue($event) {
        _vm.nationSecondmentDaysFilter = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('secondments-per-nation', {
    attrs: {
      "secondments-distribution": _vm.secondmentsDistribution,
      "is-loading": _vm.isLoading
    }
  })], 1), _c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('best-clients-chart', {
    staticClass: "mb-3",
    attrs: {
      "client-count": _vm.secondmentsCountPerClient,
      "is-loading": _vm.isLoading
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }