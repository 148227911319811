var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "label label-lg label-inline font-weight-bolder text-capitalize",
    class: _vm.variant
  }, [_vm._v(" " + _vm._s(_vm.status) + " "), _vm.past ? _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("(" + _vm._s(_vm.$t('Past')) + ")")]) : _vm._e(), _vm.started ? _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("(" + _vm._s(_vm.$t('Started')) + ")")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }