<template>
  <img v-b-tooltip.hover :title="$t(`${countriesMap[countryIso]?.name}`)" class="country-flag" :class="{ big, medium }"
    :src="`/media/flags/${countryIso.toLowerCase()}.svg`"/>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    countryIso: {
      type: String,
      required: true,
    },
    big: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("constants", ["countriesMap"]),
  },
};
</script>

<style scoped lang="scss">
.country-flag {
  min-width: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 2px;

  &.big {
    min-width: 100px;
    max-width: 100px;
    max-height: 100px;
    border-radius: 6px;
  }

  &.medium {
    min-width: 55px;
    max-width: 55px;
    max-height: 55px;
    border-radius: 6px;
  }
}
</style>
