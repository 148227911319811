var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom card-stretch gutter-b"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t("Next 10 scheduled assignments")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.$t("Show your next assignments approved")))])])]), _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_vm.nextSecondments.length > 0 ? _c('div', {
    staticClass: "card-body detail pt-4"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.nextSecondments,
      "fields": _vm.fields,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "responsive": "",
      "busy": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-primary my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-3"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(dateStart)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.dateStart ? _vm.DateService.format(item.dateStart, _vm.dateFormat) : "") + " ")];
      }
    }, {
      key: "cell(dateEnd)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.dateStart ? _vm.DateService.format(item.dateEnd, _vm.dateFormat) : "") + " ")];
      }
    }, {
      key: "cell(nation)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('CountryFlag', {
          attrs: {
            "country-iso": item.nation.iso
          }
        })], 1)];
      }
    }, {
      key: "cell(client)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('Avatar', {
          staticClass: "mr-2",
          attrs: {
            "avatar-image": item.client.logo,
            "avatar-text": item.client.initials
          }
        }), _c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('span', [_vm._v(_vm._s(item.client.name))])])], 1)];
      }
    }, {
      key: "cell(pregress)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('secondment-status-badge', {
          attrs: {
            "size": "sm",
            "status": item.status,
            "label-only": ""
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('b-button', {
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "tooltip": "See Overview"
          },
          on: {
            "click": function click($event) {
              return _vm.showDetail(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Navigation/Right-2.svg"
          }
        })], 1)])];
      }
    }], null, false, 2404666341)
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "per-page": _vm.perPage
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1) : _c('div', {
    staticClass: "text-center p-40"
  }, [_c('h3', [_c('i', {
    staticClass: "flaticon2-list-3 text-primary svg-icon-lg",
    staticStyle: {
      "font-size": "2.4rem"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("No scheduled assignments")) + " ")]), _c('span', {
    staticClass: "text-muted",
    staticStyle: {
      "font-size": "1.2rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("You will need to create an ASSIGNMENT to view the scheduled assignments table.")))]), _c('div', {
    staticClass: "pt-5 pb-4"
  }, [_c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'create-secondment'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Tools/Compass.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create Assignment")) + " ")])])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }